"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateSkinColor = generateSkinColor;
exports.generateTitle = generateTitle;
// translate router.meta.title, be used in breadcrumb sidebar tagsview
function generateTitle(title) {
  var hasKey = this.$te('route.' + title);
  var translatedTitle = this.$t('route.' + title); // $t :this method from vue-i18n, inject in @/lang/index.js
  if (hasKey) {
    return translatedTitle;
  }
  return title;
}
function generateSkinColor(color) {
  var hasKey = this.$te('skin.' + color);
  var translatedTitle = this.$t('skin.' + color); // $t :this method from vue-i18n, inject in @/lang/index.js

  if (hasKey) {
    return translatedTitle;
  }
  return color;
}