"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setStore = exports.removeStore = exports.getStore = exports.getAllStore = exports.clearStore = void 0;
require("core-js/modules/es6.number.constructor");
var _typeof2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.function.name");
var _validate = require("@/utils/validate");
/**
 * 存储localStorage
 */
var setStore = function setStore(params) {
  var name = params.name,
    content = params.content,
    type = params.type;
  var obj = {
    dataType: (0, _typeof2.default)(content),
    content: content,
    type: type,
    datetime: new Date().getTime()
  };
  if (type) window.sessionStorage.setItem(name, JSON.stringify(obj));else window.localStorage.setItem(name, JSON.stringify(obj));
};
/**
 * 获取localStorage
 */
exports.setStore = setStore;
var getStore = function getStore(params) {
  var name = params.name;
  var obj = {};
  var content;
  obj = window.localStorage.getItem(name);
  if ((0, _validate.validatenull)(obj)) obj = window.sessionStorage.getItem(name);
  if ((0, _validate.validatenull)(obj)) return;
  obj = JSON.parse(obj);
  if (obj.dataType === 'string') {
    content = obj.content;
  } else if (obj.dataType === 'number') {
    content = Number(obj.content);
  } else if (obj.dataType === 'boolean') {
    /* eslint-disable */
    content = eval(obj.content);
  } else if (obj.dataType === 'object') {
    content = obj.content;
  }
  return content;
};
/**
 * 删除localStorage
 */
exports.getStore = getStore;
var removeStore = function removeStore(params) {
  var name = params.name;
  window.localStorage.removeItem(name);
  window.sessionStorage.removeItem(name);
};

/**
 * 获取全部localStorage
 */
exports.removeStore = removeStore;
var getAllStore = function getAllStore(params) {
  var list = [];
  var type = params.type;
  for (var i = 1; i <= window.sessionStorage.length; i++) {
    if (type) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore({
          name: window.sessionStorage.key(i),
          type: 'session'
        })
      });
    } else {
      list.push(getStore({
        name: window.localStorage.key(i),
        content: getStore({
          name: window.localStorage.key(i)
        })
      }));
    }
  }
  return list;
};

/**
 * 清空全部localStorage
 */
exports.getAllStore = getAllStore;
var clearStore = function clearStore(params) {
  var type = params.type;
  if (type) {
    window.sessionStorage.clear();
    return;
  }
  window.localStorage.clear();
};
exports.clearStore = clearStore;