"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.string.sub");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _axios = _interopRequireDefault(require("axios"));
var _methods;
var _default = {
  components: {
    // Checkbox
  },
  data: function data() {
    return {
      termsChecked: false,
      platform_id: "",
      freeBalBtnDisabled: false,
      paidBalBtnDisabled: false,
      currentRow: 0,
      balanceTypeDialogVisible: false,
      langDialogVisible: false,
      isLangEn: true,
      table_pid: "",
      table_lid: "",
      merchname: sessionStorage.getItem("username"),
      branchname: sessionStorage.getItem("branchname"),
      response: "",
      dialogStatus: "",
      textMap: {
        update: "Edit",
        create: "Create"
      },
      dialogFormVisible: false,
      filters: {
        name: ""
      },
      users: [],
      total: 0,
      page: 1,
      sels: [],
      balance: 0,
      user_email: "",
      submitted: false,
      isMainChatGrey: false,
      isMianSidebarOpenSmall: true,
      SnNotShow: true,
      isAddGrey: true,
      isStyleToggled: false,
      isSidebarOpen: true,
      // 控制侧边栏显示与否
      isSnSidebarOpen: true,
      isMobile: true,
      // 标记是否为移动端
      checked: true,
      // main side bar content
      questionnaires: [],
      // second side bar content
      questions: [],
      pid: this.getUrlParam("pid"),
      // questionnaire id
      lid: this.getUrlParam("lid"),
      // license id
      cid: this.getUrlParam("cid"),
      // chat log id

      // for chatbot function
      gpt_mode: false,
      userInput: "",
      currentQuestionIndex: 0,
      current_tag: "",
      // current question tag(name)
      current_type: "",
      // current question type, like ["text", "single_choice", "multiple_choice", "multiple_object"]
      current_export_type: "",
      // current question export type
      current_choices: [],
      // current question choices
      multiple_object_last_question: "",
      // multiple object father question index
      multiple_object_length: 0,
      // multiple obejct son group length
      userInput_disabled: false,
      userInput_placeholder: "Enter your response...",
      messageList: [],
      // chat history

      // for patent api integration
      patent_questions: ["patent_title", "patent_is_owned_exclusively", "patent_year_granted", "patent_year_applied", "patent_is_high_value", "patent_forward_citations", "patent_family_forward_citations", "patent_backward_citations", "patent_non_patent_backward_citations", "patent_life_cycle", "patent_assignees", "patent_family_size", "patent_has_filed_ipc", "patent_max_claims", "patent_ipcs"],
      patent_questions_without_choices: ["patent_title", "patent_year_granted", "patent_year_applied", "patent_is_high_value", "patent_forward_citations", "patent_family_forward_citations", "patent_backward_citations", "patent_non_patent_backward_citations", "patent_assignees", "patent_family_size", "patent_max_claims", "patent_ipcs"],
      // questionnaire information
      questionList: [],
      template: [],
      // only used in 3 old questionnaires (qid = 12, 15, 16)
      industry_name: "",
      company_type_name: "",
      currency: "",
      choices: [],
      selected_choice: "",
      selected_chocies: [],
      // ChatGPT message storage
      messages: [{
        role: "system",
        content: "You are a helpful assistant."
      }],
      // for final submit
      model_questions: {},
      user_questions: {}
    };
  },
  computed: {
    buttonSize: function buttonSize() {
      return this.isMobile ? "small" : ""; // 当屏幕宽度小于或等于768px时，按钮大小为'small'，否则为默认大小
    },
    dialogSize: function dialogSize() {
      return this.isMobile ? "85%" : "40%";
    }
  },
  created: function created() {
    var _this = this;
    this.getUsers().then(function () {
      _this.user_email = window.localStorage.getItem("user_email");
      var platform_profile = JSON.parse(window.localStorage.getItem("platform_profile"));
      console.log(platform_profile);
      if (platform_profile) {
        _this.platform_id = platform_profile.platform_id;
      }
    });

    //判断是否是中文。
    if (window.localStorage.getItem("language") == "ZH") {
      this.isLangEn = false;
    }

    // if empty pid, main page, only read chat list
    if (this.pid == null) {
      (0, _axios.default)({
        method: "get",
        url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/",
        headers: {
          Authorization: window.localStorage.getItem("ova_user_token")
        }
      }).then(function (res) {
        console.log("log:");
        _this.questionnaires = res.data.data.chat_log_list;
        console.log(_this.questionnaires);
      });
      return;
    }
    // get questionnaire by pid
    (0, _axios.default)({
      method: "get",
      url: "https://api.oxvalue.ai:7999/api/question/v0.1/questionnaire/".concat(this.pid, "/"),
      headers: {
        Authorization: window.localStorage.getItem("ova_user_token")
      }
    }).then(function (res) {
      // if api response code 403, means not logged in, then clear expire token and return to login page
      if (res.data.code == 403) {
        _this.$store.dispatch("LogOut").then(function () {
          window.localStorage.removeItem("ova_user_token");
          window.localStorage.removeItem("ova_user_id");
          location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
          return;
        });
      }
      // read questionaire info
      _this.currency = res.data.data.currency;
      _this.industry_name = res.data.data.industry_name;
      _this.company_type_name = res.data.data.company_type_name;
      // read questionnaire
      var questions = JSON.parse(res.data.data.questions);
      _this.user_questions = questions;
      _this.template = questions.template; // only used in 3 old questionnaires (12 15 16)
      _this.questionList = [];
      Object.keys(questions).forEach(function (key) {
        if (key != "template") {
          Object.keys(questions[key]).forEach(function (key1) {
            questions[key][key1].answers = [];
            _this.questionList.push(questions[key][key1]);
          });
        }
      });
      _this.questions = _this.questionList;
    }).then(function () {
      // no cid in url, new chat
      if (_this.getUrlParam("cid") == null) {
        (0, _axios.default)({
          method: "post",
          url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/",
          headers: {
            Authorization: window.localStorage.getItem("ova_user_token")
          },
          data: JSON.stringify({
            chat_log: JSON.stringify({
              messageList: _this.messageList,
              currentQuestionIndex: _this.currentQuestionIndex
            }),
            question_list_1: JSON.stringify(_this.questionList),
            fk_user_license_id: _this.lid,
            fk_questionnaire_id: _this.pid
          })
        }).then(function (res) {
          _this.cid = res.data.data.id;
          (0, _axios.default)({
            method: "get",
            url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/",
            headers: {
              Authorization: window.localStorage.getItem("ova_user_token")
            }
          }).then(function (res) {
            _this.questionnaires = res.data.data.chat_log_list;
          });
          _this.appendMessage("OxValue.AI", "Hello! I'm here to assist you with the questionnaire. During the process, if you need any help, like terms explaination, you can use the info button next to the input. Let's get started on the first question now.");
          _this.ask_a_question();
        });
      } else {
        // get the chat history by cid
        (0, _axios.default)({
          method: "get",
          url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/".concat(_this.cid, "/"),
          headers: {
            Authorization: window.localStorage.getItem("ova_user_token")
          }
        }).then(function (res) {
          _this.questionList = JSON.parse(res.data.data.question_list_1);
          _this.questions = _this.questionList;
          _this.currentQuestionIndex = JSON.parse(res.data.data.chat_log).currentQuestionIndex;
          _this.messageList = JSON.parse(res.data.data.chat_log).messageList;
          // make some meaningless update to make this update time newest
          var saved_chat = {
            messageList: _this.messageList,
            currentQuestionIndex: _this.currentQuestionIndex
          };
          (0, _axios.default)({
            method: "PUT",
            url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/".concat(_this.cid, "/"),
            headers: {
              Authorization: window.localStorage.getItem("ova_user_token")
            },
            data: JSON.stringify({
              chat_log: JSON.stringify(saved_chat),
              question_list_1: JSON.stringify(_this.questionList)
            })
          }).then(function () {
            // refresh chat list so current chat is on top
            (0, _axios.default)({
              method: "get",
              url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/",
              headers: {
                Authorization: window.localStorage.getItem("ova_user_token")
              }
            }).then(function (res) {
              _this.questionnaires = res.data.data.chat_log_list;
              _this.scrollToBottom();
            });
          });
        });
      }
    });
  },
  methods: (_methods = {
    openBalDialog: function openBalDialog(row) {
      console.log(this.platform_id);
      this.termsChecked = false;
      this.balanceTypeDialogVisible = true;
      this.currentRow = row;
      this.freeBalBtnDisabled = row.free_balance == 0 ? true : false;
      this.paidBalBtnDisabled = row.balance == 0 ? true : false;
    },
    choosePaidBalance: function choosePaidBalance() {
      console.log("paid");
      if (this.termsChecked == false) {
        this.$message.error("".concat(this.isLangEn ? "Please read and agree T&C and Privacy Policy!" : "请阅读并同意服务协议和隐私权政策!"));
        return;
      }
      this.gotoChatbot(this.currentRow, "paid");
    },
    chooseFreeBalance: function chooseFreeBalance() {
      console.log("free");
      if (this.termsChecked == false) {
        this.$message.error("".concat(this.isLangEn ? "Please read and agree T&C and Privacy Policy!" : "请阅读并同意服务协议和隐私权政策!"));
        return;
      }
      this.gotoChatbot(this.currentRow, "free");
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      switch (row.industry_name) {
        case "Biopharmaceutical":
          return "biopharmaceutical";
        case "Chemical and Resource-intensive":
          return "chemical";
        case "Information and Communications Technology":
          return "information";
        case "Mechanical Device":
          return "mechanical";
        case "Medical Device":
          return "medical";
        case "Pollution Treatment":
          return "pollution";
        case "Renewable Energy":
          return "renewable";
        case "Software":
          return "software";
        default:
          return "";
      }
    },
    showSidebar: function showSidebar() {
      this.isMainChatGrey = !this.isMainChatGrey;
      this.isMianSidebarOpenSmall = !this.isMianSidebarOpenSmall;
    },
    showLanguageDialog: function showLanguageDialog() {
      this.langDialogVisible = true;
    },
    setLangEn: function setLangEn() {
      window.localStorage.setItem("language", "EN");
      this.isLangEn = true;
      this.langDialogVisible = false;
    },
    setLangZh: function setLangZh() {
      window.localStorage.setItem("language", "ZH");
      this.isLangEn = false;
      this.langDialogVisible = false;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      //   this.getUsers()
    },
    logout: function logout() {
      this.$store.dispatch("LogOut").then(function () {
        window.localStorage.removeItem("ova_user_token");
        window.localStorage.removeItem("ova_user_id");
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    },
    // 获取用户列表
    getUsers: function getUsers() {
      var _this2 = this;
      if (window.localStorage.getItem("ova_user_token") == undefined) {
        this.logout();
        return;
      }
      return (0, _axios.default)({
        method: "get",
        url: "https://api.oxvalue.ai:7999/api/license/v0.1/user_license/",
        headers: {
          Authorization: window.localStorage.getItem("ova_user_token")
        }
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 403) {
          _this2.$store.dispatch("LogOut").then(function () {
            window.localStorage.removeItem("ova_user_token");
            window.localStorage.removeItem("ova_user_id");
            location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
            return;
          });
        }
        if (response.status == 200) {
          var license_list = response.data.data.license_list;
          console.log(license_list);
          _this2.total = license_list.length;
          for (var index in license_list) {
            var license = license_list[index];
            if (license.balance >= 0) {
              _this2.users.push({
                id: license.id,
                balance: license.balance,
                free_balance: license.free_balance,
                company_type_name: license.company_type_name,
                company_type_name_zh: license.company_type_name_zh,
                create_time: license.create_time,
                fk_company_type_id: license.fk_company_type_id,
                fk_industry_id: license.fk_industry_id,
                fk_questionnaire_id: license.fk_questionnaire_id,
                fk_user_id: license.fk_user_id,
                industry_name: license.industry_name,
                industry_name_zh: license.industry_name_zh,
                info: license.info,
                update_time: license.update_time,
                user_email: license.user_email,
                user_name: license.user_name,
                state: license.state
              });
            }
          }
          if (license_list.length > 0) {
            window.localStorage.setItem("user_email", license_list[0].user_email);
          }
        } else {
          console.log("API ERROR");
        }
      });
      // const para = {
      // 	page: this.page,
      // 	name: this.filters.name
      // }
      // getUserListPage(para).then(async res => {

      // })
    },
    gotoChatbot: function gotoChatbot(row, bal) {
      console.log(row);
      this.table_pid = row.fk_questionnaire_id;
      this.table_lid = row.id;
      if (this.isLangEn == true) {
        // 去英文chatbot
        window.open("https://eva.oxvalue.ai/chatbot?pid=" + this.table_pid + "&lid=" + this.table_lid + "&bal=".concat(bal), "_self");
      } else {
        // 去中文chatbot
        window.open("https://eva.oxvalue.ai/chatbot_zh?pid=" + this.table_pid + "&lid=" + this.table_lid + "&bal=".concat(bal), "_self");
      }
      this.balanceTypeDialogVisible = false;
    },
    hideSidebars: function hideSidebars() {
      this.isSnSidebarOpen = true;
      this.isMianSidebarOpenSmall = true;
      this.SnNotShow = true;
      this.isMainChatGrey = false;
    },
    // 切换侧边栏显示状态
    toggleSidebar: function toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    // 检测是否为移动端
    checkIsMobile: function checkIsMobile() {
      this.isMobile = window.innerWidth <= 768; // 这里使用 768px 作为判断移动端的阈值，可以根据实际情况调整
    },
    goToSeconSideBar: function goToSeconSideBar() {
      this.SnNotShow = !this.SnNotShow;
      this.isMainChatGrey = !this.isMainChatGrey;
    },
    goToMainSideBar: function goToMainSideBar() {
      this.isSnSidebarOpen = !this.isSnSidebarOpen;
      this.isMianSidebarOpenSmall = !this.isMianSidebarOpenSmall;
    }
  }, (0, _defineProperty2.default)(_methods, "toggleSidebar", function toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }), (0, _defineProperty2.default)(_methods, "getUrlParam", function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  }), (0, _defineProperty2.default)(_methods, "ask_a_question", function ask_a_question() {
    // if there are still questions
    if (this.currentQuestionIndex < this.questionList.length) {
      // next question text
      var nextQuestion = this.questionList[this.currentQuestionIndex]["question_en"];
      var i = this.currentQuestionIndex + 1; // question id start from 1
      var message = "Questions " + i + ": " + nextQuestion;

      // // if question type is address, selection for country and city should be display
      // if(this.questionList[this.currentQuestionIndex]["type"] == "address"){
      //   document.getElementById('answer-options').style = "height: 28%; display: block";
      //   message = message + " Please select country and city/town then submit."
      //   displayAddressOptions();
      // }
      // if choices array of current question is not empty, display choices
      if (this.questionList[this.currentQuestionIndex]["choices"] != null && this.questionList[this.currentQuestionIndex]["choices"] != "") {
        // if current question is not one of the patent question, user should not be able to type their answer, they must select one choice
        if (this.patent_questions.indexOf(this.questionList[this.currentQuestionIndex]["name"]) == -1) {
          userInput.disabled = true;
        }
        // display choices by type (multi or single)
        if (this.questionList[this.currentQuestionIndex]["type"] == "multiple_choice") {
          message = message + ' You can select one or more options and then click "submit".';
          this.displayAnswerOptions(Object.keys(this.questionList[this.currentQuestionIndex]["choices"]));
        } else {
          message = message + " You can select from the options.";
          this.displayAnswerOptions(Object.keys(this.questionList[this.currentQuestionIndex]["choices"]));
        }
      }
      this.appendMessage("OxValue.AI", message);
      this.current_type = this.questionList[this.currentQuestionIndex]["type"];
      this.current_tag = this.questionList[this.currentQuestionIndex]["name"];
    }
  }), (0, _defineProperty2.default)(_methods, "sendMessage", function sendMessage() {
    var _this3 = this;
    this.choices = [];
    var userMessage = this.userInput;
    // avoid sending empty message
    if (userMessage.trim() !== "") {
      // when using skip
      if (userMessage.trim() == "Skip this question for now") {
        this.appendMessage("You", userMessage.trim());
        this.questionList[this.currentQuestionIndex]["answers"].push("Not Answered");
        this.userInput = "";
        // save chat

        // window.localStorage.setItem(`questionListpid${pid}lid${lid}`, JSON.stringify(questionList))
        // window.localStorage.setItem(`user_questionspid${pid}lid${lid}`, JSON.stringify(user_questions))
      }
      // not using skip
      else {
        // if gpt mode
        if (this.gpt_mode) {
          this.appendMessage("You", userMessage.trim(), true);
          this.messages.push({
            role: "user",
            content: userMessage.trim()
          });
          this.userInput_disabled = true; // while waiting for gpt api reponse, disable input box
          this.userInput = "Waiting for AI Expert response...";
          (0, _axios.default)({
            method: "POST",
            url: "https://api.openai.com/v1/chat/completions",
            headers: {
              Authorization: "Bearer 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",
              "Content-Type": "application/json"
            },
            data: {
              model: "gpt-3.5-turbo",
              messages: this.messages
            }
          }).then(function (res) {
            _this3.appendMessage("OxValue Assistant", res.data["choices"][0]["message"]["content"], true);
            _this3.messages.push({
              role: "assistant",
              content: res.data["choices"][0]["message"]["content"]
            });
          });
          this.userInput = "";
          this.userInput_disabled = false;
          return;
        }
        // not gpt mode, normal answer
        else {
          // restrictions based on question type
          if (this.current_type == "email") {
            var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(userMessage.trim())) {
              this.appendMessage("You", userMessage.trim());
              this.appendMessage("OxValue.AI", "Sorry, the answer you have provided doesn't seem to be like an email address, please check and answer again.");
              this.userInput = "";
              return;
            }
          }
          if (this.current_type == "float") {
            var floatRegex = /^-?\d+(\.\d+)?$/;
            if (!floatRegex.test(userMessage.trim())) {
              this.appendMessage("You", userMessage.trim());
              this.appendMessage("OxValue.AI", "Sorry, the answer you have provided doesn't seem to be like a digit number, please check and answer again.");
              this.userInput = "";
              return;
            }
          }
          if (this.current_type == "sic_code") {
            if (userMessage < "1110" || userMessage.trim().length > 5 || userMessage.trim().length < 4) {
              this.appendMessage("You", userMessage.trim());
              this.appendMessage("OxValue.AI", "Sorry, the answer you have provided doesn't seem to be like a valid SIC code, please check and answer again.");
              this.userInput = "";
              return;
            }
          }
          // date type restriction is not clear yet
          if (this.current_tag == "start_year") {
            if (userMessage.trim().length != 4 || userMessage.trim() > "2099" || userMessage.trim() < "1600") {
              this.appendMessage("You", userMessage.trim());
              this.appendMessage("OxValue.AI", "Sorry, the answer you have provided doesn't seem to be like a valid year, please check and answer again.");
              this.userInput = "";
              return;
            }
          }
          // not violating any restriction, append the message
          this.appendMessage("You", userMessage.trim());
          this.questionList[this.currentQuestionIndex]["answers"].push(userMessage.trim());
          /*
            save chat here
          */

          // patent API integration
          // if (this.current_tag == "patent_number") {
          //   fetch(`https://api.oxvalue.ai:7999/api/question/v0.1/patent/${userMessage}/`)
          //   .then(response => response.json())
          //   .then(response =>{
          //     if(response["data"]["patent_info_inpadoc"] != "None" && response["data"]["patent_info_inpadoc"] != ""){
          //       let patent_info = JSON.parse(response["data"]["patent_info_inpadoc"]);
          //       Object.keys(patent_info).forEach(key => {
          //         for(let i=0; i<questionList.length; i++){
          //           if(patent_info[key] == null || key == "patent_number") {
          //             break;
          //           }
          //           if(questionList[i]["name"] == key){
          //             questionList[i]["choices"] = {};
          //             // create new choices and set subquestion of these chocies to null
          //             questionList[i]["choices"][patent_info[key]] = null;
          //             break;
          //           }
          //         }
          //       });
          //     }
          //     else if(response["data"]["patent_info_minesoft"] != "None" && response["data"]["patent_info_minesoft"] != ""){
          //       patent_info = JSON.parse(response["data"]["patent_info_minesoft"]);
          //       Object.keys(patent_info).forEach(key => {
          //         for(let i=0; i<questionList.length; i++){
          //           if(patent_info[key] == null || key == "patent_number") {
          //             break;
          //           }
          //           if(questionList[i]["name"] == key){
          //             questionList[i]["choices"] = {};
          //             // create new choices and set subquestion of these chocies to null
          //             questionList[i]["choices"][patent_info[key]] = null;
          //             break;
          //           }
          //         }
          //       });
          //     }
          //   })
          // }
        }
      }

      // multiple object "loop"
      if (this.current_type == "multiple_object") {
        this.multiple_object_length = 0;
        Object.keys(this.questionList[this.currentQuestionIndex]["choices"]).forEach(function (key) {
          if (_this3.questionList[_this3.currentQuestionIndex]["choices"][key] != null) {
            // how many questions need to be skipped
            _this3.multiple_object_length += _this3.questionList[_this3.currentQuestionIndex]["choices"][key].length;
            // which question is the last question in the "loop"
            _this3.multiple_object_last_question = _this3.questionList[_this3.currentQuestionIndex]["choices"][key][_this3.questionList[_this3.currentQuestionIndex]["choices"][key].length - 1] - 1;
          }
        });
        if (userMessage == "0" || userMessage == "No" || userMessage == "Skip this question for now") {
          // getting out of the "loop", reset
          this.currentQuestionIndex += this.multiple_object_length;
          this.multiple_object_length = 0;
          this.multiple_object_last_question = "";
        }
      }
      // reach the end of the "loop", go back to the father question and -1 (because currentQuestionIndex will +1 below)
      if (parseInt(this.currentQuestionIndex) == parseInt(this.multiple_object_last_question)) {
        this.currentQuestionIndex -= this.multiple_object_length + 1;
      }
      if (this.currentQuestionIndex + 1 <= Object.keys(this.questionList).length) {
        this.currentQuestionIndex++;
        if (this.currentQuestionIndex < Object.keys(this.questionList).length) {
          while (this.currentQuestionIndex < Object.keys(this.questionList).length && this.questionList[this.currentQuestionIndex]["is_subquestion"] == true) {
            this.currentQuestionIndex++;
          }
          if (this.currentQuestionIndex < Object.keys(this.questionList).length) {
            // document.cookie = `currentQuestionIndex=${currentQuestionIndex}`;
            // window.localStorage.setItem(`currentQuestionIndexpid${pid}lid${lid}`, currentQuestionIndex)
            var nextQuestion = this.questionList[this.currentQuestionIndex]["question_en"];
            var i = this.currentQuestionIndex + 1;
            var message = "Questions " + i + ": " + nextQuestion;
            // if(this.questionList[this.currentQuestionIndex]["type"] == "address"){
            //   this.userInput_disabled = true;
            //   // document.getElementById('answer-options').style = "height: 40%; display: block";
            //   message = message + " Please select country and city/town then submit."
            //   displayAddressOptions();
            // }
            // 如果有问题选择
            if (this.questionList[this.currentQuestionIndex]["choices"] != null && this.questionList[this.currentQuestionIndex]["choices"] != "") {
              if (this.patent_questions.indexOf(this.questionList[this.currentQuestionIndex]["name"]) == -1) {
                this.userInput_disabled = true;
              }
              if (this.questionList[this.currentQuestionIndex]["type"] == "multiple_choice") {
                message = message + ' You can select one or more options and then click "submit".';
                this.displayAnswerOptions(Object.keys(this.questionList[this.currentQuestionIndex]["choices"]));
              } else {
                message = message + " You can select from the options.";
                this.displayAnswerOptions(Object.keys(this.questionList[this.currentQuestionIndex]["choices"]));
              }
            }
            this.appendMessage("OxValue.AI", message);
            this.current_type = this.questionList[this.currentQuestionIndex]["type"];
            this.current_tag = this.questionList[this.currentQuestionIndex]["name"];
          } else {
            // 如果问题已经回答完毕，显示结束消息
            this.appendMessage("OxValue.AI", "Thanks for your answers, that was the last question! You can now submit the answers and generate report using the button below.");
            // 禁用用户输入框
            // this.userInput.style = "display: None";
            // chatgptModeButton.disabled = true;
            // skipButton.disabled = true;

            // window.localStorage.removeItem(`currentQuestionIndexpid${pid}lid${lid}`)
            // window.localStorage.removeItem(`questionListpid${pid}lid${lid}`)
            // window.localStorage.removeItem(`templatepid${pid}lid${lid}`)
            // window.localStorage.removeItem(`user_questionspid${pid}lid${lid}`)
            // document.getElementById('mean-button').disabled = true;
            // document.getElementById('example-button').disabled = true;
            document.getElementById("button-container").style = "display: None";
            document.getElementById("user-input").style = "display: None";
            var generateReportButton = document.getElementById("generate-report-button");
            generateReportButton.style.display = "block";
          }
        } else {
          // Finished
          this.appendMessage("OxValue.AI", "Thanks for your answers, this is the last question! You can now submit the answers and generate report using the button below. I suggest using the Review button to review your answers again before submitting.");
          // disable all the functional buttons
          // this.userInput.style = "display: None";
          // chatgptModeButton.disabled = true;
          // skipButton.disabled = true;
          // document.getElementById('mean-button').disabled = true;
          // document.getElementById('example-button').disabled = true;
          // document.getElementById('send-button').style = "display: None";
          document.getElementById("button-container").style = "display: None";
          document.getElementById("user-input").style = "display: None";
          var _generateReportButton = document.getElementById("generate-report-button");
          _generateReportButton.style.display = "block";
        }
      }
      this.userInput = "";
      this.isSnSidebarOpen = !this.isSnSidebarOpen;
      this.isMianSidebarOpenSmall = !this.isMianSidebarOpenSmall;
      this.isSnSidebarOpen = !this.isSnSidebarOpen;
      this.isMianSidebarOpenSmall = !this.isMianSidebarOpenSmall;
    }
  }), (0, _defineProperty2.default)(_methods, "toggleChatGPTMode", function toggleChatGPTMode() {
    if (!this.gpt_mode) {
      // Entering GPT mode
      this.gpt_mode = true;
      this.userInput_disabled = false;
      this.userInput_placeholder = "AI Expert Mode - Ask AI Expert a question...";
      this.appendMessage("OxValue Assistant", "We're now in AI Expert mode, you can ask me anything you don't understand about the questionnaire, and I will answer your questions in a few seconds.", true);
    } else {
      // Exiting ChatGPT mode
      this.gpt_mode = false;
      this.userInput_placeholder = "Enter your response...";
      this.appendMessage("OxValue Assistant", "AI Expert mode exited, you can now continue answering to the questionnaire.", true);
      this.ask_a_question();
    }
  }), (0, _defineProperty2.default)(_methods, "appendMessage", function appendMessage(sender, message, gpt_mode, description) {
    var _this4 = this;
    if (sender == "OxValue.AI") {
      this.userInput_disabled = true;
      setTimeout(function () {
        _this4.messageList.push({
          role: sender,
          content: message,
          gpt_mode: gpt_mode,
          // content_cn: ,
          description: description
        });
        _this4.scrollToBottom();
        var saved_chat = {
          messageList: _this4.messageList,
          currentQuestionIndex: _this4.currentQuestionIndex
        };
        (0, _axios.default)({
          method: "PUT",
          url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/".concat(_this4.cid, "/"),
          headers: {
            Authorization: window.localStorage.getItem("ova_user_token")
          },
          data: JSON.stringify({
            chat_log: JSON.stringify(saved_chat),
            question_list_1: JSON.stringify(_this4.questionList)
          })
        }).then(function () {
          _this4.userInput_disabled = true;
        });
      }, 650);
    } else {
      this.messageList.push({
        role: sender,
        content: message,
        gpt_mode: gpt_mode,
        // content_cn: ,
        description: description
      });
      this.scrollToBottom();
      var saved_chat = {
        messageList: this.messageList,
        currentQuestionIndex: this.currentQuestionIndex
      };
      (0, _axios.default)({
        method: "PUT",
        url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/".concat(this.cid, "/"),
        headers: {
          Authorization: window.localStorage.getItem("ova_user_token")
        },
        data: JSON.stringify({
          chat_log: JSON.stringify(saved_chat),
          question_list_1: JSON.stringify(this.questionList)
        })
      });
    }
  }), (0, _defineProperty2.default)(_methods, "scrollToBottom", function scrollToBottom() {
    var _this5 = this;
    this.$nextTick(function () {
      _this5.$refs.chat.scrollTop = _this5.$refs.chat.scrollHeight;
    });
  }), (0, _defineProperty2.default)(_methods, "skip", function skip() {
    this.userInput = "Skip this question for now";
    this.choices = [];
    this.sendMessage();
    this.userInput_disabled = false;
  }), (0, _defineProperty2.default)(_methods, "ask_meaning", function ask_meaning() {
    var _this6 = this;
    this.appendMessage("You", "What does this mean?", true);
    this.messages.push({
      role: "user",
      content: "What does this question means: " + this.questionList[this.currentQuestionIndex]["question_en"]
    });
    (0, _axios.default)({
      method: "POST",
      url: "https://api.openai.com/v1/chat/completions",
      headers: {
        Authorization: "Bearer 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",
        "Content-Type": "application/json"
      },
      data: {
        model: "gpt-3.5-turbo",
        messages: this.messages
      }
    }).then(function (res) {
      _this6.appendMessage("OxValue Assistant", res.data["choices"][0]["message"]["content"], true);
      _this6.messages.push({
        role: "assistant",
        content: res.data["choices"][0]["message"]["content"]
      });
    });
  }), (0, _defineProperty2.default)(_methods, "ask_exmaple", function ask_exmaple() {
    var _this7 = this;
    this.appendMessage("You", "Give me an example", true);
    this.messages.push({
      role: "user",
      content: "Give me a general example answer to this question, as short as possible, it doesn't need to really have any meaning, please reply with only the answer: " + this.questionList[this.currentQuestionIndex]["question_en"]
    });
    (0, _axios.default)({
      method: "POST",
      url: "https://api.openai.com/v1/chat/completions",
      headers: {
        Authorization: "Bearer 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",
        "Content-Type": "application/json"
      },
      data: {
        model: "gpt-3.5-turbo",
        messages: this.messages
      }
    }).then(function (res) {
      _this7.appendMessage("OxValue Assistant", res.data["choices"][0]["message"]["content"], true);
      _this7.messages.push({
        role: "assistant",
        content: res.data["choices"][0]["message"]["content"]
      });
    });
  }), (0, _defineProperty2.default)(_methods, "displayAnswerOptions", function displayAnswerOptions(options) {
    var _this8 = this;
    setTimeout(function () {
      _this8.userInput_disabled = true;
      _this8.userInput_placeholder = "Please select from the choice(s) above.";
      for (var i = 0; i < options.length; i++) {
        _this8.choices.push({
          name: options[i],
          is_selected: false
        });
      }
    }, 650);
  }), (0, _defineProperty2.default)(_methods, "select", function select(choice, index) {
    // already selected, remove selected
    if (this.choices[index].is_selected) {
      // this.selected_chocies.splice(this.choices.indexOf(choice), 1)
      this.choices[index].is_selected = false;
    }
    // not selected yet, selected
    else {
      // before select this one, if current question is not multiple choice, remove all previous selected
      if (this.current_type != "multiple_choice") {
        for (var i = 0; i < this.choices.length; i++) {
          this.choices[i].is_selected = false;
        }
      }
      // this.selected_chocies.push(choice)
      this.choices[index].is_selected = true;
    }
  }), (0, _defineProperty2.default)(_methods, "submit", function submit() {
    var _this9 = this;
    var selected_chocies = [];
    for (var i = 0; i < this.choices.length; i++) {
      if (this.choices[i].is_selected) {
        selected_chocies.push(this.choices[i].name);
      }
    }
    if (selected_chocies.length <= 0) {
      return;
    }
    selected_chocies.forEach(function (option) {
      if (_this9.questionList[_this9.currentQuestionIndex]["choices"][option] != null) {
        _this9.questionList[_this9.currentQuestionIndex]["choices"][option].forEach(function (index) {
          _this9.questionList[parseInt(index) - 1]["is_subquestion"] = false;
        });
      }
    });
    this.userInput = selected_chocies.toString();
    this.userInput_disabled = false;
    this.userInput_placeholder = "Enter your response...";
    this.sendMessage();
  }), (0, _defineProperty2.default)(_methods, "goToChat", function goToChat(qnr) {
    if (qnr.description == "" || JSON.parse(qnr.description).language == "en") {
      window.open("https://eva.oxvalue.ai/chatbot?pid=".concat(qnr.fk_questionnaire_id, "&lid=").concat(qnr.fk_user_license_id, "&cid=").concat(qnr.id), "_self");
    } else if (JSON.parse(qnr.description).language == "zh") {
      window.open("https://eva.oxvalue.ai/chatbot_zh?pid=".concat(qnr.fk_questionnaire_id, "&lid=").concat(qnr.fk_user_license_id, "&cid=").concat(qnr.id), "_self");
    }
  }), (0, _defineProperty2.default)(_methods, "goToLicense", function goToLicense() {
    window.open("https://eva.oxvalue.ai/newLicense", "_self");
  }), (0, _defineProperty2.default)(_methods, "goToSubmission", function goToSubmission() {
    window.open("https://eva.oxvalue.ai/newSubmission", "_self");
  }), (0, _defineProperty2.default)(_methods, "submitToServer", function submitToServer() {
    var _this10 = this;
    this.appendMessage("OxValue.AI", "Data submitted, you can check your submissions now.");
    this.submitted = true;
    document.getElementById("generate-report-button").style = "display: none";
    if (this.pid <= 16) {
      var _loop = function _loop() {
        _this10.model_questions[i] = _this10.questionList[_this10.template[i] - 1];
        // skipped questions or sub questions
        if (_this10.questionList[_this10.template[i] - 1]["answers"][0] == "Not Answered" || _this10.questionList[_this10.template[i] - 1]["answers"].length == 0) {
          _this10.model_questions[i]["answers"] = ["0"];
        }
        // questions that are currency
        if (_this10.template[i] - 2 >= 0) {
          if (_this10.questionList[_this10.template[i] - 2]["answers"][0] == "¥") {
            var _loop2 = function _loop2(j) {
              var url = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=CNY&amount=".concat(_this10.model_questions[i]["answers"][j], "&target=").concat(_this10.currency);
              var headers = new Headers();
              var token = window.localStorage.getItem("ova_user_token");
              headers.append("Authorization", token);
              fetch(url, {
                method: "GET",
                headers: headers
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_questions[i]["answers"][j] = res["data"]["amount"];
              });
            };
            for (var j = 0; j < _this10.model_questions[i]["answers"].length; j++) {
              _loop2(j);
            }
          }
          if (_this10.questionList[_this10.template[i] - 2]["answers"][0] == "€") {
            var _loop3 = function _loop3(j) {
              var url = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=EUR&amount=".concat(_this10.model_questions[i]["answers"][j], "&target=").concat(_this10.currency);
              var headers = new Headers();
              var token = window.localStorage.getItem("ova_user_token");
              headers.append("Authorization", token);
              fetch(url, {
                method: "GET",
                headers: headers
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_questions[i]["answers"][j] = res["data"]["amount"];
              });
            };
            for (var _j = 0; _j < _this10.model_questions[i]["answers"].length; _j++) {
              _loop3(_j);
            }
          }
          if (_this10.questionList[_this10.template[i] - 2]["answers"][0] == "$") {
            var _loop4 = function _loop4(j) {
              var url = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=USD&amount=".concat(_this10.model_questions[i]["answers"][j], "&target=").concat(_this10.currency);
              var headers = new Headers();
              var token = window.localStorage.getItem("ova_user_token");
              headers.append("Authorization", token);
              fetch(url, {
                method: "GET",
                headers: headers
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_questions[i]["answers"][j] = res["data"]["amount"];
              });
            };
            for (var _j2 = 0; _j2 < model_questions[i]["answers"].length; _j2++) {
              _loop4(_j2);
            }
          }
          if (_this10.questionList[_this10.template[i] - 2]["answers"][0] == "£") {
            var _loop5 = function _loop5(j) {
              var url = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=GBP&amount=".concat(_this10.model_questions[i]["answers"][j], "&target=").concat(_this10.currency);
              var headers = new Headers();
              var token = window.localStorage.getItem("ova_user_token");
              headers.append("Authorization", token);
              fetch(url, {
                method: "GET",
                headers: headers
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_questions[i]["answers"][j] = res["data"]["amount"];
              });
            };
            for (var _j3 = 0; _j3 < _this10.model_questions[i]["answers"].length; _j3++) {
              _loop5(_j3);
            }
          }
        }
        // 1/0 export type, "Yes" = 1, "No" = 0
        if (_this10.questionList[_this10.template[i] - 1]["export_type"] == "number") {
          //for(let k=0; k < questionList[template[i]-1]["answers"].length; k++){
          if (_this10.questionList[_this10.template[i] - 1]["answers"] == "Yes") {
            _this10.model_questions[i]["answers"] = ["1"];
          } else {
            _this10.model_questions[i]["answers"] = ["0"];
          }
          //}
        }
        // index_0 export type, index starts from 0
        if (_this10.questionList[_this10.template[i] - 1]["export_type"] == "index_0") {
          var model_answer = [];
          for (var k = 0; k < _this10.questionList[_this10.template[i] - 1]["answers"].length; k++) {
            var answer = _this10.questionList[_this10.template[i] - 1]["answers"][k];
            for (var _j4 = 0; _j4 < _this10.questionList[_this10.template[i] - 1]["choices"].length; _j4++) {
              if (answer == _this10.questionList[_this10.template[i] - 1]["choices"][_j4]) {
                model_answer.push(_j4 + "");
                break;
              }
            }
          }
          _this10.model_questions[i]["answers"] = model_answer;
        }
        // index_1 export type, index starts from 1
        if (_this10.questionList[_this10.template[i] - 1]["export_type"] == "index_1") {
          var _model_answer = [];
          for (var _k = 0; _k < _this10.questionList[_this10.template[i] - 1]["answers"].length; _k++) {
            var _answer = _this10.questionList[_this10.template[i] - 1]["answers"][_k];
            for (var _j5 = 0; _j5 < _this10.questionList[_this10.template[i] - 1]["choices"].length; _j5++) {
              if (_answer == _this10.questionList[_this10.template[i] - 1]["choices"][_j5]) {
                _model_answer.push(_j5 + 1 + "");
                break;
              }
            }
          }
          _this10.model_questions[i]["answers"] = _model_answer;
        }
        // customize export type, option's coresponding value is customized
        if (_this10.questionList[_this10.template[i] - 1]["export_type"] == "customize") {
          var _model_answer2 = [];
          var _loop6 = function _loop6(j) {
            Object.keys(_this10.questionList[_this10.template[i] - 1]["custom"]["customize_values"]).forEach(function (key) {
              if (_this10.questionList[_this10.template[i] - 1]["answers"][j] == key) {
                _model_answer2.push(_this10.questionList[_this10.template[i] - 1]["custom"]["customize_values"][key]);
              }
            });
          };
          for (var _j6 = 0; _j6 < _this10.questionList[_this10.template[i] - 1]["answers"].length; _j6++) {
            _loop6(_j6);
          }
          _this10.model_questions[i]["answers"] = _model_answer2;
        }
      };
      for (var i in this.template) {
        _loop();
      }
    } else {
      this.model_questions = JSON.parse(JSON.stringify(this.questionList));
      var _loop7 = function _loop7(i) {
        var model_answer = [];
        var _loop8 = function _loop8() {
          var ans = _this10.model_questions[i]["answers"][j];
          if (ans == "Not Answered") {
            model_answer.push("0");
            return "continue";
          }
          // unify currency
          if (i > 0) {
            if (Object.keys(_this10.model_questions[i - 1]["choices"])[j] == "¥") {
              var url = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=CNY&amount=".concat(ans, "&target=").concat(_this10.currency);
              var headers = new Headers();
              var token = window.localStorage.getItem("ova_user_token");
              headers.append("Authorization", token);
              fetch(url, {
                method: "GET",
                headers: headers
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_answer.push(res["data"]["amount"]);
              });
            } else if (Object.keys(model_questions[i - 1]["choices"])[j] == "€") {
              var _url = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=EUR&amount=".concat(ans, "&target=").concat(_this10.currency);
              var _headers = new Headers();
              var _token = window.localStorage.getItem("ova_user_token");
              _headers.append("Authorization", _token);
              fetch(_url, {
                method: "GET",
                headers: _headers
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_answer.push(res["data"]["amount"]);
              });
            } else if (Object.keys(model_questions[i - 1]["choices"])[j] == "$") {
              var _url2 = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=USD&amount=".concat(ans, "&target=").concat(_this10.currency);
              var _headers2 = new Headers();
              var _token2 = window.localStorage.getItem("ova_user_token");
              _headers2.append("Authorization", _token2);
              fetch(_url2, {
                method: "GET",
                headers: _headers2
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_answer.push(res["data"]["amount"]);
              });
            } else if (Object.keys(model_questions[i - 1]["choices"])[j] == "£") {
              var _url3 = "https://api.oxvalue.ai:7999/api/question/v0.1/currency/?current=GBP&amount=".concat(ans, "&target=").concat(_this10.currency);
              var _headers3 = new Headers();
              var _token3 = window.localStorage.getItem("ova_user_token");
              _headers3.append("Authorization", _token3);
              fetch(_url3, {
                method: "GET",
                headers: _headers3
              }).then(function (res) {
                return res.json();
              }).then(function (res) {
                _this10.model_answer.push(res["data"]["amount"]);
              });
            }
          }
          // transform answers according to export type
          if (_this10.model_questions[i]["export_type"] == "1/0" || _this10.model_questions[i]["export_type"] == "number") {
            if (ans == "yes" || ans == "Yes" || ans == "YES") {
              _this10.model_answer.push("1");
            } else if (ans == "no" || ans == "No" || ans == "NO") {
              _this10.model_answer.push("0");
            }
          }
          if (_this10.model_questions[i]["export_type"] == "index_0") {
            for (var k = 0; k < Object.keys(_this10.model_questions[i]["choices"]).length; k++) {
              if (ans == Object.keys(_this10.model_questions[i]["choices"])[k]) {
                _this10.model_answer.push(k + "");
              }
            }
          }
          if (_this10.model_questions[i]["export_type"] == "index_1") {
            for (var _k2 = 0; _k2 < Object.keys(_this10.model_questions[i]["choices"]).length; _k2++) {
              if (ans == Object.keys(_this10.model_questions[i]["choices"])[_k2]) {
                _this10.model_answer.push(_k2 + 1 + "");
              }
            }
          }
          if (_this10.model_questions[i]["export_type"] == "customize") {
            Object.keys(_this10.model_questions[i]["custom"]["customize_values"]).forEach(function (key) {
              if (ans == key) {
                _this10.model_answer.push(_this10.model_questions[i]["custom"]["customize_values"][key]);
              }
            });
          }
          // address type questions, transform to [country_id, region_id, city_id]
          // if (this.model_questions[i]["type"] == "address") {
          //     let country = this.model_questions[i]["answers"][0].split(",")[0];
          //     let city = this.model_questions[i]["answers"][0].split(",")[1];
          //     fetch(`https://api.oxvalue.ai:7999/api/question/v0.1/country/`, {
          //         method: 'GET',
          //     }).then(res => res.json()).then(res => {
          //         let country_id = '';
          //         let country_list = res.data.country_list;
          //         for(let x = 0; x < country_list.length; x++){
          //             // use answer country name to get country id
          //             if (country_list[x]['name'] == country) {
          //                 country_id = country_list[x]['id']
          //                 break;
          //             }
          //         }
          //         // use country id to get city list
          //         fetch(`https://api.oxvalue.ai:7999/api/question/v0.1/city/?country_id=${country_id}`, {
          //             method: 'GET',
          //         }).then(res1 => res1.json()).then(res1 => {
          //             let city_id = '';
          //             let region_name = '';
          //             let city_list = res1.data.city_list;
          //             for(let x = 0; x < city_list.length; x++){
          //                 // use answer city to get city id and region name
          //                 if (city_list[x]['name'] == city) {
          //                     city_id = city_list[x]['id'];
          //                     region_name = city_list[x]['region'];
          //                     break;
          //                 }
          //             }
          //             // use country id to get region list
          //             fetch(`https://api.oxvalue.ai:7999/api/question/v0.1/region/?country_id=${country_id}`, {
          //                 method: 'GET',
          //             }).then(res2 => res2.json()).then(res2 => {
          //                 let region_id = "";
          //                 let region_list = res2.data.region_list;
          //                 for(let x = 0; x < region_list.length; x++){
          //                     // use region name to get region id
          //                     if (region_list[x]['name'] == region_name) {
          //                         region_id = region_list[x]['id']
          //                         break;
          //                     }
          //                 }
          //                 // combine three ids
          //                 this.model_questions[i]["answers"] = `${country_id} ${region_id} ${city_id}`
          //             })
          //         })
          //     })
          // }
        };
        for (var j = 0; j < _this10.model_questions[i]["answers"].length; j++) {
          var _ret = _loop8();
          if (_ret === "continue") continue;
        }
        if (_this10.model_answer.length != 0) {
          _this10.model_questions[i]["answers"] = model_answer;
        }
      };
      for (var _i = 0; _i < this.model_questions.length; _i++) {
        _loop7(_i);
      }
    }
    var subquestions = [];
    Object.keys(this.model_questions).forEach(function (key) {
      if (_this10.model_questions[key]["type"] == "multiple_object") {
        Object.keys(_this10.model_questions[key]["choices"]).forEach(function (choice) {
          if (_this10.model_questions[key]["choices"][choice] != null) {
            Object.keys(_this10.model_questions[key]["choices"][choice]).forEach(function (sub) {
              subquestions.push(_this10.model_questions[key]["choices"][choice][sub]);
            });
          }
        });
      }
    });

    // transform answer array [1,2,3] into string "1 2 3"
    Object.keys(this.model_questions).forEach(function (key) {
      // only transform questions that are not sub questions of a multiple object question
      if (subquestions.indexOf(parseInt(key) + 1) == -1 && _this10.model_questions[key]["type"] != "multiple_choice") {
        if (_this10.model_questions[key]["answers"] != null && _this10.model_questions[key]["answers"].length != 0 && _this10.model_questions[key]["answers"] != [] && _this10.model_questions[key]["type"] != "address") {
          var a = "";
          _this10.model_questions[key]["answers"].forEach(function (answer) {
            a = a + answer + " ";
          });
          a = a.slice(0, -1);
          _this10.model_questions[key]["answers"] = a;
        } else {
          _this10.model_questions[key]["answers"] = "0";
        }
      } else {
        if (_this10.model_questions[key]["answers"].length == 0) {
          _this10.model_questions[key]["answers"] = ["0"];
        }
      }
    });

    // fill questions not designed to be answered by the user, need to change in the future
    if (this.industry_name == "Biopharmaceutical") {
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "2023"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "8.58"
      };
    } else if (pid <= 16) {
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "2023"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "7800"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "8.58"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "165.80"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "1"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
      this.model_questions[Object.keys(this.model_questions).length] = {
        answers: "0"
      };
    }
    fetch("https://api.oxvalue.ai:7999/api/question/v0.1/submission/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("ova_user_token")
      },
      body: JSON.stringify({
        description: "",
        license_id: this.getUrlParam("lid"),
        questionnaire_id: this.getUrlParam("pid"),
        user_inputs: JSON.stringify(this.user_questions),
        model_inputs: JSON.stringify(this.model_questions)
      })
    }).then(function (response) {
      console.log(response);
    });
  }), _methods),
  beforeDestroy: function beforeDestroy() {
    // 移除窗口大小变化监听
    window.removeEventListener("resize", this.checkIsMobile);
  },
  mounted: function mounted() {
    // keep updating isMobile
    this.checkIsMobile();
    window.addEventListener("resize", this.checkIsMobile);
  }
};
exports.default = _default;