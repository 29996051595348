var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择货币单位",
        visible: _vm.visible,
        width: _vm.dialogWidth,
        "append-to-body": "",
        "custom-class": "currency-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("p", [_vm._v("ID: " + _vm._s(_vm.selectedRow.id))]),
      _vm._v(" "),
      _c("p", [
        _vm._v("公司类型: " + _vm._s(_vm.selectedRow.company_type_name_zh)),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("行业: " + _vm._s(_vm.selectedRow.industry_name_zh))]),
      _vm._v(" "),
      _c("p", [_vm._v("即将查看: " + _vm._s(_vm.currentAction))]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          attrs: { placeholder: "请选择货币单位" },
          model: {
            value: _vm.selectedCurrency,
            callback: function ($$v) {
              _vm.selectedCurrency = $$v
            },
            expression: "selectedCurrency",
          },
        },
        [
          _c("el-option", { attrs: { label: "人民币 (CNY)", value: "CNY" } }),
          _vm._v(" "),
          _c("el-option", { attrs: { label: "美元 (USD)", value: "USD" } }),
          _vm._v(" "),
          _c("el-option", { attrs: { label: "英镑 (GBP)", value: "GBP" } }),
          _vm._v(" "),
          _c("el-option", { attrs: { label: "欧元 (EUR)", value: "EUR" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }