var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "submissionContainer" },
    [
      _c(
        "div",
        { staticClass: "submission-tile" },
        [
          _c("Icon", { attrs: { type: "circle", extraClass: "icon" } }),
          _vm._v(" "),
          _c("div", { staticClass: "title-word" }, [_vm._v("全部")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "submission-card" },
        [
          _c(
            "el-table",
            {
              staticClass: "custom-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": _vm.tableHeader,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  sortable: "",
                  label: "ID",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "company_type_name_zh",
                  sortable: "",
                  label: "公司类型",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "industry_name_zh",
                  sortable: "",
                  label: "行业",
                  align: "center",
                  "class-name": "industry-column",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "config.license_type",
                  label: "授权类型",
                  align: "center",
                  formatter: _vm.licenseTypeFormatter,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { class: _vm.getStateClass(scope.row.state) },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.stateFormatter(scope.row.state)) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  align: "center",
                  "min-width": _vm.isMobile ? "74" : "186",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.config.is_locked === true
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isBtnShow(scope.row),
                                        expression: "isBtnShow(scope.row)",
                                      },
                                    ],
                                    staticClass: "custom-btn",
                                    class: _vm.getButtonClass(scope.row.state),
                                    attrs: {
                                      disabled: _vm.isButtonDisabled(scope.row),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUnlockDialog(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("解锁")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isBtnShow(scope.row),
                                        expression: "isBtnShow(scope.row)",
                                      },
                                    ],
                                    staticClass: "custom-btn",
                                    class: _vm.getButtonClass(scope.row.state),
                                    attrs: {
                                      disabled: _vm.isButtonDisabled(scope.row),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickReport(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("报告")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isBtnShow(scope.row),
                                        expression: "isBtnShow(scope.row)",
                                      },
                                    ],
                                    staticClass: "custom-btn",
                                    class: _vm.getButtonClass(scope.row.state),
                                    attrs: {
                                      disabled: _vm.isButtonDisabled(scope.row),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickCertificate(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("证书")]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CurrencyDialog", {
        attrs: {
          visible: _vm.currencyDialogVisible,
          selectedRow: _vm.selectedRow,
          currentAction: _vm.currentAction,
        },
        on: {
          close: function ($event) {
            _vm.currencyDialogVisible = false
          },
          confirm: _vm.confirmCurrency,
        },
      }),
      _vm._v(" "),
      _c("UnlockDialog", {
        attrs: {
          visible: _vm.unlockDialogVisible,
          selectedRow: _vm.selectedRow,
          currentItem: _vm.currentItem,
        },
        on: {
          close: function ($event) {
            _vm.unlockDialogVisible = false
          },
          confirm: _vm.confirmUnlock,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }