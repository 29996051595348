"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _validate = require("@/utils/validate");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'nx-top-lock',
  data: function data() {
    return {
      box: false,
      form: {
        passwd: ''
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['lockPasswd'])),
  props: [],
  methods: {
    handleSetLock: function handleSetLock() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this.$store.commit('SET_LOCK_PASSWD', _this.form.passwd);
          _this.handleLock();
        }
      });
    },
    handleLock: function handleLock() {
      var _this2 = this;
      if ((0, _validate.validatenull)(this.lockPasswd)) {
        this.box = true;
        return;
      }
      this.$store.commit('SET_LOCK');
      setTimeout(function () {
        _this2.$router.push({
          path: '/lock'
        });
      }, 100);
    }
  },
  components: {}
};
exports.default = _default;