"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _nxSvgIcon = _interopRequireDefault(require("@/components/nx-svg-icon"));
var _generateIconsView = _interopRequireDefault(require("@/views/icons/svg-icons/generateIconsView.js"));
// svg组件
// just for @/views/icons , you can delete it
// register globally
_vue.default.component('svg-icon', _nxSvgIcon.default);
var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};
var req = require.context('./svg', false, /\.svg$/);
var iconMap = requireAll(req);
_generateIconsView.default.generate(iconMap);