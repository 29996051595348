"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Icon = _interopRequireDefault(require("./Icon.vue"));
var _ChatlogCardList = _interopRequireDefault(require("./ChatlogCardList.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Icon: _Icon.default,
    ChatlogCardList: _ChatlogCardList.default
  },
  props: {
    currentView: {
      type: String,
      required: true
    }
  }
};
exports.default = _default;