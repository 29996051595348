"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogVisible: true,
      pid: "",
      lid: "",
      merchname: sessionStorage.getItem("username"),
      branchname: sessionStorage.getItem("branchname"),
      response: "",
      dialogStatus: "",
      textMap: {
        update: "Edit",
        create: "Create"
      },
      dialogFormVisible: false,
      filters: {
        name: ""
      },
      users: [],
      total: 0,
      page: 1,
      sels: [],
      balance: 0
    };
  },
  methods: {
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      //   this.getUsers()
    },
    logout: function logout() {
      this.$store.dispatch("LogOut").then(function () {
        window.localStorage.removeItem("ova_user_token");
        window.localStorage.removeItem("ova_user_id");
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    },
    // 获取用户列表
    getUsers: function getUsers() {
      var _this = this;
      if (window.localStorage.getItem("ova_user_token") == undefined) {
        this.logout();
        return;
      }
      (0, _axios.default)({
        method: "get",
        url: "https://api.oxvalue.ai:7999/api/license/v0.1/user_license/",
        headers: {
          Authorization: window.localStorage.getItem("ova_user_token")
        }
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 403) {
          _this.$store.dispatch("LogOut").then(function () {
            window.localStorage.removeItem("ova_user_token");
            window.localStorage.removeItem("ova_user_id");
            location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
            return;
          });
        }
        if (response.status == 200) {
          var license_list = response.data.data.license_list;
          _this.total = license_list.length;
          for (var index in license_list) {
            var license = license_list[index];
            if (license.balance >= 0) {
              _this.users.push({
                id: license.id,
                balance: license.balance,
                company_type_name: license.company_type_name,
                create_time: license.create_time,
                fk_company_type_id: license.fk_company_type_id,
                fk_industry_id: license.fk_industry_id,
                fk_questionnaire_id: license.fk_questionnaire_id,
                fk_user_id: license.fk_user_id,
                industry_name: license.industry_name,
                info: license.info,
                update_time: license.update_time,
                user_email: license.user_email,
                user_name: license.user_name,
                state: license.state
              });
            }
          }
          if (license_list.length > 0) {
            window.localStorage.setItem("user_email", license_list[0].user_email);
          }
        } else {
          console.log("API ERROR");
        }
      });
      // const para = {
      // 	page: this.page,
      // 	name: this.filters.name
      // }
      // getUserListPage(para).then(async res => {

      // })
    },
    gotoChatbot: function gotoChatbot(index, row) {
      console.log(index);
      console.log(row);
      // console.log(row.fk_questionnaire_id)
      // console.log(row.company_type_name)
      this.dialogVisible = true;
      this.pid = row.fk_questionnaire_id;
      this.lid = row.id;
      // window.open(
      //   "https://eva.oxvalue.ai/chatbot?pid=" +
      //     row.fk_questionnaire_id +
      //     "&lid=" +
      //     row.id,
      //   "_self"
      // );
    },
    goToEnChatbot: function goToEnChatbot() {
      // window.open(
      //   "http://localhost:9528/chatbot?pid=" + this.pid + "&lid=" + this.lid,
      //   "_self"
      // );
      window.open("https://eva.oxvalue.ai/newLicense", "_self");
      this.dialogVisible = false;
    },
    goToZhChatbot: function goToZhChatbot() {
      // window.open(
      //   "http://localhost:9528/chatbot_zh?pid=" + this.pid + "&lid=" + this.lid,
      //   "_self"
      // );
      window.open("https://eva.oxvalue.ai/newLicense", "_self");
      this.dialogVisible = false;
    }
  },
  created: function created() {
    this.getUsers();
  }
};
exports.default = _default;