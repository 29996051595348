"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _mockjs = _interopRequireDefault(require("mockjs"));
var _utils = require("@/utils");
var List = [];
var count = 60;
for (var i = 0; i < count; i++) {
  List.push(_mockjs.default.mock({
    id: _mockjs.default.Random.guid(),
    name: _mockjs.default.Random.cname(),
    addr: _mockjs.default.mock('@county(true)'),
    'age|18-60': 1,
    birth: _mockjs.default.Random.date(),
    sex: _mockjs.default.Random.integer(0, 1)
  }));
}
var _default = {
  getUserList: function getUserList(config) {
    var _param2Obj = (0, _utils.param2Obj)(config.url),
      name = _param2Obj.name,
      _param2Obj$page = _param2Obj.page,
      page = _param2Obj$page === void 0 ? 1 : _param2Obj$page,
      _param2Obj$limit = _param2Obj.limit,
      limit = _param2Obj$limit === void 0 ? 20 : _param2Obj$limit;
    var mockList = List.filter(function (user) {
      if (name && user.name.indexOf(name) === -1) return false;
      return true;
    });
    var pageList = mockList.filter(function (item, index) {
      return index < limit * page && index >= limit * (page - 1);
    });
    return {
      code: 0,
      data: {
        total: mockList.length,
        users: pageList
      }
    };
  },
  createUser: function createUser(config) {
    var _param2Obj2 = (0, _utils.param2Obj)(config.url),
      id = _param2Obj2.id,
      name = _param2Obj2.name,
      addr = _param2Obj2.addr,
      age = _param2Obj2.age,
      birth = _param2Obj2.birth,
      sex = _param2Obj2.sex;
    console.log('66');
    List.push({
      id: id,
      name: name,
      addr: addr,
      age: age,
      birth: birth,
      sex: sex
    });
    return {
      code: 0,
      data: {
        message: '添加成功'
      }
    };
  },
  deleteUser: function deleteUser(config) {
    var _param2Obj3 = (0, _utils.param2Obj)(config.url),
      id = _param2Obj3.id;
    List = List.filter(function (u) {
      return u.id !== id;
    });
    return {
      code: 0,
      data: {
        message: '删除成功'
      }
    };
  },
  batchremove: function batchremove(config) {
    var _param2Obj4 = (0, _utils.param2Obj)(config.url),
      ids = _param2Obj4.ids;
    ids = ids.split(',');
    List = List.filter(function (u) {
      return !ids.includes(u.id);
    });
    return {
      code: 0,
      data: {
        message: '批量删除成功'
      }
    };
  },
  updateUser: function updateUser(config) {
    var _param2Obj5 = (0, _utils.param2Obj)(config.url),
      id = _param2Obj5.id,
      name = _param2Obj5.name,
      addr = _param2Obj5.addr,
      age = _param2Obj5.age,
      birth = _param2Obj5.birth,
      sex = _param2Obj5.sex;
    var sex_num = parseInt(sex);
    List.some(function (u) {
      if (u.id === id) {
        u.name = name;
        u.addr = addr;
        u.age = age;
        u.birth = birth;
        u.sex = sex_num;
        return true;
      }
    });
    return {
      code: 0,
      data: {
        message: '编辑成功'
      }
    };
  }
};
exports.default = _default;