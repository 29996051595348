"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Page404',
  data: function data() {
    return {};
  },
  computed: {
    message: function message() {
      return '此网页不存在...';
    }
  }
};
exports.default = _default;