"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nxIcon = _interopRequireDefault(require("@/components/nx-icon"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'nx-github',
  components: {
    nxIcon: _nxIcon.default
  },
  data: function data() {
    return {};
  },
  methods: {
    handleClick: function handleClick() {
      window.open(this.url);
    }
  }
};
exports.default = _default;