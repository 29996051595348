"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  title: 'OxValue.AI',
  logo: 'O',
  author: '',
  whiteList: ['/login', '/404', '/401', '/lock'],
  lockPage: '/lock',
  info: {
    title: 'OxValue.AI'
    // list: [
    //   '将外卖平台的订单自动发送到金字招牌',
    //   '目前支持：HungryPanda、Deliveroo、Uber Eats、Fantuan',
    // ]
  },

  wel: {
    // title: 'OxValue.AI 第三方外卖集成',
    // list: [
    //   '将外卖平台的订单自动发送到金字招牌',
    //   '目前支持：HungryPanda、Deliveroo、Uber Eats、Fantuan',
    // ]
  }
};
exports.default = _default;