"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _slicedToArray2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
require("core-js/modules/web.dom.iterable");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.function.name");
var tagsView = {
  state: {
    visitedViews: [],
    cachedViews: []
  },
  mutations: {
    ADD_VISITED_VIEWS: function ADD_VISITED_VIEWS(state, view) {
      if (state.visitedViews.some(function (v) {
        return v.path === view.fullPath;
      })) return;
      state.visitedViews.push({
        name: view.name,
        path: view.fullPath,
        title: view.meta.title || 'no-name'
      });
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name);
      }
    },
    DEL_VISITED_VIEWS: function DEL_VISITED_VIEWS(state, view) {
      var _iterator = (0, _createForOfIteratorHelper2.default)(state.visitedViews.entries()),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = (0, _slicedToArray2.default)(_step.value, 2),
            i = _step$value[0],
            v = _step$value[1];
          if (v.path === view.path) {
            state.visitedViews.splice(i, 1);
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      var _iterator2 = (0, _createForOfIteratorHelper2.default)(state.cachedViews),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _i = _step2.value;
          if (_i === view.name) {
            var index = state.cachedViews.indexOf(_i);
            state.cachedViews.splice(index, 1);
            break;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    DEL_OTHERS_VIEWS: function DEL_OTHERS_VIEWS(state, view) {
      var _iterator3 = (0, _createForOfIteratorHelper2.default)(state.visitedViews.entries()),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var _step3$value = (0, _slicedToArray2.default)(_step3.value, 2),
            i = _step3$value[0],
            v = _step3$value[1];
          if (v.path === view.path) {
            state.visitedViews = state.visitedViews.slice(i, i + 1);
            break;
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      var _iterator4 = (0, _createForOfIteratorHelper2.default)(state.cachedViews),
        _step4;
      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var _i2 = _step4.value;
          if (_i2 === view.name) {
            var index = state.cachedViews.indexOf(_i2);
            state.cachedViews = state.cachedViews.slice(index, _i2 + 1);
            break;
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
    },
    DEL_ALL_VIEWS: function DEL_ALL_VIEWS(state) {
      state.visitedViews = [];
      state.cachedViews = [];
    }
  },
  actions: {
    addVisitedViews: function addVisitedViews(_ref, view) {
      var commit = _ref.commit;
      commit('ADD_VISITED_VIEWS', view);
    },
    delVisitedViews: function delVisitedViews(_ref2, view) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve) {
        commit('DEL_VISITED_VIEWS', view);
        resolve((0, _toConsumableArray2.default)(state.visitedViews));
      });
    },
    delOthersViews: function delOthersViews(_ref3, view) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve) {
        commit('DEL_OTHERS_VIEWS', view);
        resolve((0, _toConsumableArray2.default)(state.visitedViews));
      });
    },
    delAllViews: function delAllViews(_ref4) {
      var commit = _ref4.commit,
        state = _ref4.state;
      return new Promise(function (resolve) {
        commit('DEL_ALL_VIEWS');
        resolve((0, _toConsumableArray2.default)(state.visitedViews));
      });
    }
  }
};
var _default = tagsView;
exports.default = _default;