"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iconfontVersion = exports.iconfontUrl = exports.env = exports.codeUrl = exports.baseUrl = void 0;
/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 老项目域名地址
 * khglUrl: 客户管理域名地址
 * dicUrl : 字典服务器地址
 * routerMode: 路由模式
 * imgBaseUrl: 图片所在域名地址
 * welUrl :默认欢迎页
 *
 */

var baseUrl = '';
exports.baseUrl = baseUrl;
var iconfontVersion = ['567566_r22zi6t8noas8aor'];
exports.iconfontVersion = iconfontVersion;
var iconfontUrl = "//at.alicdn.com/t/font_$key.css";
exports.iconfontUrl = iconfontUrl;
var codeUrl = "".concat(baseUrl, "/code");
exports.codeUrl = codeUrl;
var env = process.env;
exports.env = env;
if (env.NODE_ENV === 'development') {
  exports.baseUrl = baseUrl = ""; // 开发环境地址
} else if (env.NODE_ENV === 'production') {
  exports.baseUrl = baseUrl = ""; // 生产环境地址
} else if (env.NODE_ENV === 'test') {
  exports.baseUrl = baseUrl = ""; // 测试环境地址
}