"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//

var padding = 15; // tag's padding
var _default = {
  name: 'scrollPane',
  data: function data() {
    return {
      left: 0
    };
  },
  methods: {
    handleScroll: function handleScroll(e) {
      var eventDelta = e.wheelDelta || -e.deltaY * 3;
      var $container = this.$refs.scrollContainer;
      var $containerWidth = $container.offsetWidth;
      var $wrapper = this.$refs.scrollWrapper;
      var $wrapperWidth = $wrapper.offsetWidth;
      if (eventDelta > 0) {
        this.left = Math.min(0, this.left + eventDelta);
      } else {
        if ($containerWidth - padding < $wrapperWidth) {
          if (this.left < -($wrapperWidth - $containerWidth + padding)) {
            this.left = this.left;
          } else {
            this.left = Math.max(this.left + eventDelta, $containerWidth - $wrapperWidth - padding);
          }
        } else {
          this.left = 0;
        }
      }
    },
    moveToTarget: function moveToTarget($target) {
      var $container = this.$refs.scrollContainer;
      var $containerWidth = $container.offsetWidth;
      var $targetLeft = $target.offsetLeft;
      var $targetWidth = $target.offsetWidth;
      if ($targetLeft < -this.left) {
        // tag in the left
        this.left = -$targetLeft + padding;
      } else if ($targetLeft + padding > -this.left && $targetLeft + $targetWidth < -this.left + $containerWidth - padding) {
        // tag in the current view
        // eslint-disable-line
      } else {
        // tag in the right
        this.left = -($targetLeft - ($containerWidth - $targetWidth) + padding);
      }
    }
  }
};
exports.default = _default;