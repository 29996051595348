"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.constructor");
var _axios = _interopRequireDefault(require("axios"));
var _NavItem = _interopRequireDefault(require("./NavItem.vue"));
var _SubmissionTableCard = _interopRequireDefault(require("./SubmissionTableCard.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    NavItem: _NavItem.default,
    SubmissionTableCard: _SubmissionTableCard.default
  },
  data: function data() {
    return {
      groupedSubmissionList: {},
      users: [{
        id: "1",
        company_type_name: "STARTUP",
        company_type_name_zh: "初创公司",
        create_time: "2023-12-18T00:00:00Z",
        fk_company_type_id: "1",
        fk_industry_id: "3",
        fk_questionnaire_id: "OpenAI",
        fk_user_id: "OpenAI",
        industry_name: "Information and Communications Technology",
        industry_name_zh: "信息与通信技术",
        update_time: "2023-12-18T00:00:00Z",
        user_email: "OpenAI",
        user_name: "OpenAI",
        state: "finished",
        report_state: "finished",
        description: "OpenAI",
        config: {
          license_type: 1,
          is_locked: false
        }
      }],
      platform_id: 1
    };
  },
  methods: {
    getUsers: function getUsers() {
      var _this = this;
      return (0, _axios.default)({
        method: "get",
        url: "https://api.oxvalue.ai:7999/api/question/v0.1/submission/",
        headers: {
          Authorization: window.localStorage.getItem("ova_user_token")
        }
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 403) {
          _this.$store.dispatch("LogOut").then(function () {
            window.localStorage.removeItem("ova_user_token");
            window.localStorage.removeItem("ova_user_id");
            location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
            return;
          });
        }
        if (response.status == 200) {
          var submission_list = response.data.data.submission_list;
          console.log(submission_list);
          for (var index in submission_list) {
            var submission = submission_list[index];
            if (submission.state == "pending") {
              var newState = "pending";
            } else if (submission.state == "executing") {
              var newState = "executing";
            } else if (submission.state == "failed") {
              var newState = "failed";
            } else if (submission.report_state == "executing") {
              var newState = "executing";
            } else if (submission.report_state == "failed") {
              var newState = "failed";
            } else if (submission.report_state == "finished") {
              var newState = "finished";
            } else {
              var newState = "failed";
            }
            var user = {
              id: submission.id,
              company_type_name: submission.company_type_name,
              company_type_name_zh: submission.company_type_name_zh,
              create_time: submission.create_time,
              currency: submission.currency,
              fk_company_type_id: submission.fk_company_type_id,
              fk_industry_id: submission.fk_industry_id,
              fk_questionnaire_id: submission.fk_questionnaire_id,
              fk_user_id: submission.fk_user_id,
              industry_name: submission.industry_name,
              industry_name_zh: submission.industry_name_zh,
              update_time: submission.update_time,
              user_email: submission.user_email,
              user_name: submission.user_name,
              state: newState,
              report_state: submission.report_state,
              description: submission.description,
              config: submission.config
            };
            _this.users.push(user);
          }
          _this.groupedSubmissionList = _this.groupByIndustry(_this.users);
          console.log(_this.groupedSubmissionList);
        } else {
          console.log("API ERROR");
        }
      });
    },
    /*   *****工具函数*****   */getUrlParam: function getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    groupByIndustry: function groupByIndustry(array) {
      return array.reduce(function (acc, obj) {
        var key = obj.industry_name_zh;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }
  },
  created: function created() {
    var _this2 = this;
    this.getUsers().then(function () {
      var platform_profile = JSON.parse(window.localStorage.getItem("platform_profile"));
      console.log(platform_profile);
      if (platform_profile) {
        _this2.platform_id = platform_profile.platform_id;
      }
    });

    // 单独给天津用的邮箱做判断，如果是天津邮箱，就把不给他们看报告和证书
    if (this.user_email == "ning_jiang@innocaregroup.com") {
      console.log("ning_jiang@innocaregroup.com");
      this.btnDisabled = true;
    }
  }
};
exports.default = _default;