var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-container" },
    [
      _vm.isMobile
        ? _c("Icon", {
            attrs: { type: "aside", extraClass: "sidebar-toggle" },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("toggleAside")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile ? _c("div", { staticClass: "logo" }) : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "profile" },
            [
              _c("Icon", { attrs: { type: "yonghu", extraClass: "icon" } }),
              _vm._v(" "),
              _c("span", { staticClass: "email" }, [
                _vm._v(_vm._s(_vm.user_email)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "langBtn" },
        [
          _c(
            "el-button",
            { staticClass: "btn", attrs: { icon: "el-icon-setting" } },
            [_vm._v("语言")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }