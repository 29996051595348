"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mockjs = _interopRequireDefault(require("mockjs"));
var _table = _interopRequireDefault(require("./table"));
var _login = _interopRequireDefault(require("./login"));
var _article = _interopRequireDefault(require("./article"));
// 设置全局延时 没有延时的话有时候会检测不到数据变化 建议保留
_mockjs.default.setup({
  timeout: '300-600'
});

// 登录相关
_mockjs.default.mock(/\/user\/login/, 'post', _login.default.loginByUsername);
_mockjs.default.mock(/\/user\/logout/, 'post', _login.default.logout);
_mockjs.default.mock(/\/user\/info\.*/, 'get', _login.default.getUserInfo);

// 文章相关
_mockjs.default.mock(/\/article\/list/, 'get', _article.default.getList);
_mockjs.default.mock(/\/article\/detail/, 'get', _article.default.getArticle);
_mockjs.default.mock(/\/article\/pv/, 'get', _article.default.getPv);
_mockjs.default.mock(/\/article\/create/, 'post', _article.default.createArticle);
_mockjs.default.mock(/\/article\/update/, 'post', _article.default.updateArticle);

// 用户相关
_mockjs.default.mock(/\/user\/listpage/, 'get', _table.default.getUserList);
_mockjs.default.mock(/\/user\/remove/, 'get', _table.default.deleteUser);
_mockjs.default.mock(/\/user\/batchremove/, 'get', _table.default.batchremove);
_mockjs.default.mock(/\/user\/add/, 'get', _table.default.createUser);
_mockjs.default.mock(/\/user\/edit/, 'get', _table.default.updateUser);
var _default = _mockjs.default;
exports.default = _default;