"use strict";

var _interopRequireWildcard = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/home/ubuntu/user-website-test/node_modules/core-js/modules/es6.array.iterator.js");
require("/home/ubuntu/user-website-test/node_modules/core-js/modules/es6.promise.js");
require("/home/ubuntu/user-website-test/node_modules/core-js/modules/es6.object.assign.js");
require("/home/ubuntu/user-website-test/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _vCharts = _interopRequireDefault(require("v-charts"));
var _vueBaiduMap = _interopRequireDefault(require("vue-baidu-map"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
require("@/assets/css/iconfont/1.0.0/index.css");
require("normalize.css/normalize.css");
require("@/styles/index.scss");
var _vContextmenu = _interopRequireDefault(require("v-contextmenu"));
require("v-contextmenu/dist/index.css");
require("./mock");
require("./errorLog");
require("@/assets/library/font-awesome-4.7.0/css/font-awesome.min.css");
var _App = _interopRequireDefault(require("./App"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _lang = _interopRequireDefault(require("./lang"));
var _global = require("@/global/global");
var _util = require("./utils/util");
var _env = require("@/config/env");
require("@/icons");
require("@/permission");
var filters = _interopRequireWildcard(require("./filters"));
// 地图

// lang i18n
/* icofont*/

// css
// A modern alternative to CSS resets
// global css
// 右键菜单

// simulation data

// error log
// font-awesome

// Internationalization

_vue.default.prototype.global = _global.global;
// global filters
// register global utility filters.
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.use(_vCharts.default);
_vue.default.use(_vContextmenu.default);
_vue.default.use(_elementUi.default, {
  locale: _en.default
});
_env.iconfontVersion.forEach(function (ele) {
  (0, _util.loadStyle)(_env.iconfontUrl.replace('$key', ele));
});
_vue.default.use(_elementUi.default, {
  size: 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
// 地图
_vue.default.use(_vueBaiduMap.default, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'oW2UEhdth2tRbEE4FUpF9E5YVDCIPYih'
});
// 加载用户主题
// if (localStorage.getItem('themeValue')) {
//   global.changeTheme(localStorage.getItem('themeValue'))
// } else {
//   global.changeTheme('default')
// }
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  // template: '<App/>',
  // components: { App }
  render: function render(h) {
    return h(_App.default);
  }
});