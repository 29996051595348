"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//

var map = {
  value: "icon-guzhi",
  jilu: "icon-jilu",
  yonghu: "icon-yonghu",
  edit: "icon-edit",
  type: "icon-type",
  industry: "icon-hangye",
  circle: "icon-jichu_yuanquanxuanzhong",
  aside: "icon-zuocecebianlan"
};
var _default = {
  props: {
    type: {
      type: String,
      required: true
    },
    extraClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    iconClass: function iconClass() {
      return map[this.type];
    }
  }
};
exports.default = _default;