"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var data = {
  state: {
    iconsMap: []
  },
  generate: function generate(iconsMap) {
    this.state.iconsMap = iconsMap;
  }
};
var _default = data;
exports.default = _default;