"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.function.name");
var _nxScrollPane = _interopRequireDefault(require("@/components/nx-scroll-pane"));
var _i18n = require("@/utils/i18n");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'tagsView',
  components: {
    nxScrollPane: _nxScrollPane.default
  },
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    };
  },
  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    }
  },
  watch: {
    $route: function $route() {
      this.addViewTags();
      this.moveToCurrentTag();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted: function mounted() {
    this.addViewTags();
  },
  methods: {
    generateTitle: _i18n.generateTitle,
    // generateTitle by vue-i18n
    generateRoute: function generateRoute() {
      if (this.$route.name) {
        return this.$route;
      }
      return false;
    },
    isActive: function isActive(route) {
      return route.path === this.$route.path;
    },
    addViewTags: function addViewTags() {
      var route = this.generateRoute();
      if (!route) {
        return false;
      }
      this.$store.dispatch('addVisitedViews', route);
    },
    moveToCurrentTag: function moveToCurrentTag() {
      var _this = this;
      var tags = this.$refs.tag;
      this.$nextTick(function () {
        var _iterator = (0, _createForOfIteratorHelper2.default)(tags),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var tag = _step.value;
            if (tag.to === _this.$route.path) {
              _this.$refs.scrollPane.moveToTarget(tag.$el);
              break;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this2 = this;
      this.$store.dispatch('delVisitedViews', view).then(function (views) {
        if (_this2.isActive(view)) {
          var latestView = views.slice(-1)[0];
          if (latestView) {
            _this2.$router.push(latestView.path);
          } else {
            _this2.$router.push('/');
          }
        }
      });
    },
    closeOthersTags: function closeOthersTags() {
      var _this3 = this;
      this.$router.push(this.selectedTag.path);
      this.$store.dispatch('delOthersViews', this.selectedTag).then(function () {
        _this3.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags() {
      this.$store.dispatch('delAllViews');
      this.$router.push('/');
    },
    openMenu: function openMenu(tag, e) {
      this.visible = true;
      this.selectedTag = tag;
      this.left = e.clientX;
      this.top = e.clientY;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    }
  }
};
exports.default = _default;