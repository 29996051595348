"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _ErrorLog = _interopRequireDefault(require("@/components/ErrorLog"));
var _nxTopLock = _interopRequireDefault(require("@/components/nx-top-lock"));
var _nxBreadcrumb = _interopRequireDefault(require("@/components/nx-breadcrumb"));
var _nxHamburger = _interopRequireDefault(require("@/components/nx-hamburger"));
var _index = _interopRequireDefault(require("@/components/nx-help/index"));
var _index2 = _interopRequireDefault(require("@/components/nx-full-screen/index"));
var _index3 = _interopRequireDefault(require("@/components/nx-lang-select/index"));
var _index4 = _interopRequireDefault(require("@/components/nx-skin/index"));
var _index5 = _interopRequireDefault(require("@/components/nx-github/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'navBar',
  components: {
    nxBreadcrumb: _nxBreadcrumb.default,
    nxHamburger: _nxHamburger.default,
    nxHelp: _index.default,
    nxFullScreen: _index2.default,
    nxLangSelect: _index3.default,
    nxSkin: _index4.default,
    nxTopLock: _nxTopLock.default,
    nxGithub: _index5.default,
    ErrorLog: _ErrorLog.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'name', 'avatar'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('ToggleSideBar');
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        window.localStorage.removeItem('ova_user_token');
        window.localStorage.removeItem('ova_user_id');
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    }
  }
};
exports.default = _default;