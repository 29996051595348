"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = require("@/router");
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.indexOf(role) >= 0;
    });
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouterMap, roles) {
  var accessedRouters = asyncRouterMap.filter(function (route) {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}
var permission = {
  state: {
    routers: _router.constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = _router.constantRouterMap.concat(routers);
      console.log('state.routers', state.routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        var roles = data.roles;
        var accessedRouters;
        if (roles.indexOf('admin') >= 0) {
          console.log('admin>=0');
          accessedRouters = _router.asyncRouterMap;
        } else {
          console.log('admin<0');
          accessedRouters = filterAsyncRouter(_router.asyncRouterMap, roles);
          // accessedRouters = ''
          // accessedRouters = asyncRouterMap
        }

        console.log('accessedRouters', accessedRouters);
        commit('SET_ROUTERS', accessedRouters);
        resolve();
      });
    }
  }
};
var _default = permission;
exports.default = _default;