"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _logo = _interopRequireDefault(require("./logo"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SidebarItem: _SidebarItem.default,
    logo: _logo.default
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'permission_routers'])), {}, {
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};
exports.default = _default;