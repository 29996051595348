"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var userMap = {
  admin: {
    code: 0,
    roles: ['admin'],
    token: 'admin',
    // introduction: '我是超级管理员',
    // avatar: 'https://raw.githubusercontent.com/mgbq/nx-admin/master/src/assets/img/home/logo.png',
    name: 'Super Admin'
  },
  editor: {
    code: 0,
    roles: ['editor'],
    token: 'editor',
    // introduction: '我是编辑',
    // avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Normal Editor'
  },
  imenus: {
    code: 0,
    roles: ['imenus'],
    token: 'imenus',
    name: 'imenus'
  }
  // cqwd: {
  //   code: 0,
  //   roles: ['cqwd'],
  //   token: 'cqwd',
  //   name: 'cqwd'
  // }
};
var _default = {
  loginByUsername: function loginByUsername(config) {
    var _JSON$parse = JSON.parse(config.body),
      username = _JSON$parse.username;
    return {
      code: 0,
      roles: [username],
      token: username,
      name: username
    };
  },
  getUserInfo: function getUserInfo(config) {
    var _param2Obj = (0, _utils.param2Obj)(config.url),
      token = _param2Obj.token;
    if (true) {
      return {
        code: 0,
        roles: [username],
        token: username,
        name: username
      };
    } else {
      return false;
    }
  },
  logout: function logout() {
    return {
      code: 0,
      Message: 'success'
    };
  }
};
exports.default = _default;