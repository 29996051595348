var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainContainer" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("仪表板/估值许可")]),
      _vm._v(" "),
      _c("div", { staticClass: "main" }, [
        _c(
          "div",
          { staticClass: "navBar" },
          _vm._l(Object.keys(_vm.groupedLicenseList), function (item, index) {
            return _c(
              "div",
              { key: index },
              [_c("NavItem", { attrs: { industry: item } })],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          _vm._l(Object.values(_vm.groupedLicenseList), function (item, index) {
            return _c("IndustryCard", {
              key: index,
              attrs: { tableData: item, platform_id: _vm.platform_id },
              on: { "btn-click": _vm.handleClick },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _vm.currentRow
        ? _c("BalanceTypeDialog", {
            attrs: { row: _vm.currentRow, visible: _vm.dialogVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }