"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/utils/i18n");
var _global = require("@/global/global");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 国际化主题名字
var _default = {
  name: 'nx-skin',
  data: function data() {
    return {
      skins: [{
        name: 'Blue',
        label: 'Blue'
      }, {
        name: 'Green',
        label: 'Green'
      }, {
        name: 'Red',
        label: 'Red'
      }, {
        name: 'Purple',
        label: 'Purple'
      }, {
        name: 'Default',
        label: 'Default'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    generateSkinColor: _i18n.generateSkinColor,
    handleCommandSkin: function handleCommandSkin(command) {
      _global.global.changeTheme(command);
    }
  }
};
exports.default = _default;