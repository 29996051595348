var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        { attrs: { effect: "dark", content: "疑问", placement: "bottom" } },
        [
          _c(
            "el-button",
            {
              staticClass: " btn-text can-hover",
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = true
                },
              },
            },
            [_c("nx-icon", { attrs: { name: "question-circle" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "帮助",
            width: "700px",
            visible: _vm.dialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "-25px" } },
            [
              _c("h1", [_vm._v("如果你有问题可以加入交流群或者联系作者")]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-alert", {
                        attrs: {
                          closable: false,
                          type: "info",
                          title: "扫码进 QQ 群",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "qr",
                        attrs: { src: "/static/img/me/qq.jpg" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-alert", {
                        attrs: {
                          closable: false,
                          type: "info",
                          title: "作者微信 加好友拉进微信群",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "qr",
                        attrs: { src: "/static/img/me/we.jpg" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }