"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  data: function data() {
    return {
      localVisible: this.visible,
      localData: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: true
    }
  },
  watch: {
    visible: function visible(newVal) {
      this.localVisible = newVal;
    }
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$emit("update:visible", false);
    }
  },
  created: function created() {
    console.log("tabeldata");
    console.log(this.tableData);
    var ansArr = this.tableData.map(function (item) {
      return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
        time: "".concat(item.File, ": ").concat(item.Start, "-").concat(item.End)
      });
    });
    console.log(ansArr);
    this.localData = ansArr;
  }
};
exports.default = _default2;