"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      response: "",
      dialogStatus: "",
      textMap: {
        update: "Edit",
        create: "Create"
      },
      dialogFormVisible: false,
      filters: {
        name: ""
      },
      users: [{
        id: "1",
        company_type_name: "STARTUP",
        create_time: "2023-12-18T00:00:00Z",
        fk_company_type_id: "1",
        fk_industry_id: "3",
        fk_questionnaire_id: "OpenAI",
        fk_user_id: "OpenAI",
        industry_name: "Information and Communication Technology",
        update_time: "2023-12-18T00:00:00Z",
        user_email: "OpenAI",
        user_name: "OpenAI",
        state: "finished",
        report_state: "OpenAI",
        descripton: "OpenAI",
        result: "OpenAI"
      }],
      total: 0,
      page: 1,
      sels: [],
      balance: 0
    };
  },
  methods: {
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      //   this.getUsers()
    },
    logout: function logout() {
      this.$store.dispatch("LogOut").then(function () {
        window.localStorage.removeItem("ova_user_token");
        window.localStorage.removeItem("ova_user_id");
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    },
    // 获取用户列表
    getUsers: function getUsers() {
      var _this = this;
      (0, _axios.default)({
        method: "get",
        url: "https://api.oxvalue.ai:7999/api/question/v0.1/submission/",
        headers: {
          Authorization: window.localStorage.getItem("ova_user_token")
        }
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 403) {
          _this.$store.dispatch("LogOut").then(function () {
            window.localStorage.removeItem("ova_user_token");
            window.localStorage.removeItem("ova_user_id");
            location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
            return;
          });
        }
        if (response.status == 200) {
          var submission_list = response.data.data.submission_list;
          console.log(submission_list);
          _this.total = submission_list.length;
          for (var index in submission_list) {
            var submission = submission_list[index];
            var user = {
              id: submission.id,
              company_type_name: submission.company_type_name,
              create_time: submission.create_time,
              fk_company_type_id: submission.fk_company_type_id,
              fk_industry_id: submission.fk_industry_id,
              fk_questionnaire_id: submission.fk_questionnaire_id,
              fk_user_id: submission.fk_user_id,
              industry_name: submission.industry_name,
              update_time: submission.update_time,
              user_email: submission.user_email,
              user_name: submission.user_name,
              state: submission.state,
              report_state: submission.report_state,
              descripton: submission.descripton,
              result: submission.result
            };
            if (user.report_state == "failed" && user.state != "finished") {
              user.state = "failed";
            }
            _this.users.push(user);
          }
        } else {
          console.log("API ERROR");
        }
      });
      // const para = {
      // 	page: this.page,
      // 	name: this.filters.name
      // }
      // getUserListPage(para).then(async res => {

      // })
    },
    gotoReport: function gotoReport(row) {
      if (row.id == "1") {
        window.open("https://eva.oxvalue.ai/report_OpenAI.html");
      } else {
        window.open("https://eva.oxvalue.ai/report.html?sid=" + row.id);
      }
    },
    gotoReportDetail: function gotoReportDetail(row) {
      window.open("https://eva.oxvalue.ai/report.html?sid=" + row.id);
    },
    gotocertificate: function gotocertificate(row) {
      if (row.id == "1") {
        window.open("https://eva.oxvalue.ai/certificate_OpenAI.html");
      }
      window.open("https://eva.oxvalue.ai/certificate.html?sid=".concat(row.id));
    }
  },
  created: function created() {
    this.getUsers();
  }
};
exports.default = _default;