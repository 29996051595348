"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _IndexLayout = _interopRequireDefault(require("../layout/IndexLayout.vue"));
var _Header = _interopRequireDefault(require("../components/Header.vue"));
var _Aside = _interopRequireDefault(require("../components/Aside.vue"));
var _Main = _interopRequireDefault(require("../components/Main.vue"));
var _MainSubmission = _interopRequireDefault(require("../components/MainSubmission.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    IndexLayout: _IndexLayout.default,
    Header: _Header.default,
    Aside: _Aside.default,
    Main: _Main.default,
    MainSubmission: _MainSubmission.default
  },
  data: function data() {
    return {
      currentView: "license"
    };
  },
  methods: {
    changeView: function changeView(view) {
      this.currentView = view;
    },
    toggleAside: function toggleAside() {
      this.$refs.layout.openAside();
    }
  }
};
exports.default = _default;