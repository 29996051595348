"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _app = _interopRequireDefault(require("./modules/app"));
var _user = _interopRequireDefault(require("./modules/user"));
var _common = _interopRequireDefault(require("./modules/common"));
var _getters = _interopRequireDefault(require("./getters"));
var _fullScreen = _interopRequireDefault(require("./modules/fullScreen"));
var _permission = _interopRequireDefault(require("./modules/permission"));
var _tagsView = _interopRequireDefault(require("./modules/tagsView"));
var _errorLog = _interopRequireDefault(require("./modules/errorLog"));
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    user: _user.default,
    common: _common.default,
    fullScreen: _fullScreen.default,
    permission: _permission.default,
    tagsView: _tagsView.default,
    errorLog: _errorLog.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;