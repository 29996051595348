"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _website = _interopRequireDefault(require("@/const/website"));
var common = {
  state: {
    website: _website.default
  },
  actions: {},
  mutations: {}
};
var _default = common;
exports.default = _default;