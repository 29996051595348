var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardContainer" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("估值案例")]),
        _vm._v(" "),
        _c("div", { staticClass: "chatlogId" }, [_vm._v(_vm._s(_vm.qnr.id))]),
        _vm._v(" "),
        _c("Icon", { attrs: { type: "edit", extraClass: "icon" } }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.company_name))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "type" },
        [
          _c("Icon", { attrs: { type: "type", extraClass: "icon-1" } }),
          _vm._v(" "),
          _c("div", { staticClass: "title-1" }, [_vm._v("类型")]),
          _vm._v(" "),
          _c("div", { staticClass: "details" }, [
            _vm._v(_vm._s(_vm.qnr.company_type_name_zh)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "industry" },
        [
          _c("Icon", { attrs: { type: "industry", extraClass: "icon-1" } }),
          _vm._v(" "),
          _c("div", { staticClass: "title-1" }, [_vm._v("行业")]),
          _vm._v(" "),
          _c("div", { staticClass: "details" }, [
            _vm._v(_vm._s(_vm.qnr.industry_name_zh)),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }