"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
var _store = require("@/utils/store");
var user = {
  state: {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    roles: [],
    isLock: (0, _store.getStore)({
      name: 'isLock'
    }) || false,
    lockPasswd: (0, _store.getStore)({
      name: 'lockPasswd'
    }) || '',
    browserHeaderTitle: (0, _store.getStore)({
      name: 'browserHeaderTitle'
    }) || 'OxValue.AI'
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_LOCK_PASSWD: function SET_LOCK_PASSWD(state, lockPasswd) {
      state.lockPasswd = lockPasswd;
      (0, _store.setStore)({
        name: 'lockPasswd',
        content: state.lockPasswd,
        type: 'session'
      });
    },
    SET_LOCK: function SET_LOCK(state, action) {
      state.isLock = true;
      (0, _store.setStore)({
        name: 'isLock',
        content: state.isLock,
        type: 'session'
      });
    },
    CLEAR_LOCK: function CLEAR_LOCK(state, action) {
      state.isLock = false;
      state.lockPasswd = '';
      (0, _store.removeStore)({
        name: 'lockPasswd'
      });
      (0, _store.removeStore)({
        name: 'isLock'
      });
    },
    SET_BROWSERHEADERTITLE: function SET_BROWSERHEADERTITLE(state, action) {
      state.browserHeaderTitle = action.browserHeaderTitle;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      console.log(userInfo);
      var username = '';
      var code = '';
      if (userInfo != undefined) {
        username = userInfo.email.trim();
        code = userInfo.code;
      } else {
        username = "platform";
      }
      return new Promise(function (resolve, reject) {
        (0, _login.login)(username, code).then(function (response) {
          var data = response;
          (0, _auth.setToken)(data.token);
          commit('SET_TOKEN', data.token);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)(state.token).then(function (response) {
          var data = response;
          if (data.roles && data.roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles);
          } else {
            reject('getInfo: roles must be a non-null array !');
          }
          commit('SET_NAME', data.name);
          commit('SET_AVATAR', data.avatar);
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('CLEAR_LOCK');
          (0, _auth.removeToken)();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        resolve();
      });
    },
    // 动态修改权限
    ChangeRoles: function ChangeRoles(_ref5, role) {
      var commit = _ref5.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', role);
        (0, _auth.setToken)(role);
        (0, _login.getInfo)(role).then(function (response) {
          var data = response;
          commit('SET_ROLES', data.roles);
          commit('SET_NAME', data.name);
          commit('SET_AVATAR', data.avatar);
          resolve();
        });
      });
    }
  }
};
var _default = user;
exports.default = _default;