var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "fa",
    class: "fa-" + _vm.name,
    attrs: { "aria-hidden": "true" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }