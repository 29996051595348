"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: Boolean,
    selectedRow: Object,
    currentAction: String
  },
  data: function data() {
    return {
      selectedCurrency: ""
    };
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return window.innerWidth <= 768 ? "90%" : "30%";
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit("close");
      this.selectedCurrency = "";
    },
    handleConfirm: function handleConfirm() {
      if (!this.selectedCurrency) {
        this.$message.warning("请选择货币单位");
        return;
      }
      this.$emit("confirm", this.selectedCurrency);
      this.handleClose();
    }
  }
};
exports.default = _default;