var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aside-container" }, [
    _c("div", { staticClass: "aside-wrap" }, [
      _c("div", { staticClass: "logo" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "plusBtn" },
        [
          _c(
            "el-button",
            {
              staticClass: "custom-plusBtn",
              on: {
                click: function ($event) {
                  return _vm.$emit("change-view", "license")
                },
              },
            },
            [
              _c("div", { staticClass: "insideBtn newBtn" }, [
                _vm._v("新建估值"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "insideBtn fontPlus" }, [_vm._v("+")]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "btnGroup" }, [
        _c(
          "div",
          {
            staticClass: "btnGroup-child",
            class: { active: _vm.currentView === "license" },
          },
          [
            _c("Icon", {
              attrs: { type: "value", extraClass: "icon" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("change-view", "license")
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wordBtn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("change-view", "license")
                  },
                },
              },
              [_vm._v("\n          估值许可\n        ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btnGroup-child",
            class: { active: _vm.currentView === "submission" },
          },
          [
            _c("Icon", {
              attrs: { type: "jilu", extraClass: "icon" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("change-view", "submission")
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wordBtn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("change-view", "submission")
                  },
                },
              },
              [_vm._v("\n          估值记录\n        ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chatlog" }, [
        _c("div", { staticClass: "chatlogTitle" }, [_vm._v("历史估值记录")]),
        _vm._v(" "),
        _c("div", { staticClass: "cardWrap" }, [_c("ChatlogCardList")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }