var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("IndexLayout", {
    ref: "layout",
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function () {
          return [
            _c("Aside", {
              attrs: { currentView: _vm.currentView },
              on: { "change-view": _vm.changeView },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "head",
        fn: function () {
          return [_c("Header", { on: { toggleAside: _vm.toggleAside } })]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _vm.currentView === "license" ? _c("Main") : _vm._e(),
            _vm._v(" "),
            _vm.currentView === "submission" ? _c("main-submission") : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }