var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.questionnaires, function (qnr, index) {
      return _c("ChatlogCard", {
        key: index,
        staticClass: "card",
        attrs: { qnr: qnr },
        nativeOn: {
          click: function ($event) {
            return _vm.goToChat(qnr)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }