var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            width: "80%",
            title: "多模态答案",
            visible: _vm.localVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.localVisible = $event
            },
            close: _vm.closeDialog,
          },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "el-table",
        { attrs: { data: _vm.localData, height: "60vh", border: "" } },
        [
          _c("el-table-column", {
            attrs: { property: "question_id", label: "问题id", width: "90" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "question_zh", label: "问题", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "answer", label: "答案", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "FormalParagraphText", label: "原文" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "time", label: "出处", width: "180" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }