var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "industryContainer" }, [
    _c(
      "div",
      { staticClass: "industry-tile" },
      [
        _c("Icon", { attrs: { type: "circle", extraClass: "icon" } }),
        _vm._v(" "),
        _c("div", { staticClass: "title-word" }, [
          _vm._v(_vm._s(_vm.tableData[0].industry_name_zh)),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "industry-card" },
      [
        _c(
          "el-table",
          {
            staticClass: "custom-table",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "header-cell-style": _vm.tableHeader,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "company_type_name_zh",
                label: "公司类型",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "industry_name_zh",
                label: "行业",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "balance", label: "估值次数", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.platform_id == "2" ? "不限" : scope.row.balance
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "free_balance",
                label: "估值次数(免费)",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                label: "操作",
                align: "center",
                "min-width": _vm.isMobile ? "110" : "132",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "custom-btn",
                          attrs: {
                            disabled: scope.row.fk_questionnaire_id === "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleClick(scope.row)
                            },
                          },
                        },
                        [_vm._v("新的案例")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }