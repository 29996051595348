var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bigBox" },
    [
      _c(
        "div",
        {
          staticClass: "mainSideBar",
          class: { mainSBinMobil: _vm.isMianSidebarOpenSmall },
          staticStyle: { "z-index": "10" },
        },
        [
          _c("div", { staticClass: "mainSBLogo" }, [
            _c("div", { staticClass: "logo" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "141.799",
                    height: "17.831",
                    viewBox: "0 0 141.799 17.831",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_1173",
                      "data-name": "Path 1173",
                      d: "M12.32,26.351a14.559,14.559,0,0,1-5.829-1.06,8.136,8.136,0,0,1-3.722-3.06,8.658,8.658,0,0,1-1.285-4.782,8.724,8.724,0,0,1,1.285-4.8A8.107,8.107,0,0,1,6.491,9.58,14.559,14.559,0,0,1,12.32,8.52a14.537,14.537,0,0,1,5.842,1.06,8.116,8.116,0,0,1,3.709,3.073,8.724,8.724,0,0,1,1.285,4.8,8.658,8.658,0,0,1-1.285,4.782,8.136,8.136,0,0,1-3.722,3.06A14.559,14.559,0,0,1,12.32,26.351Zm0-3.047a9.767,9.767,0,0,0,3.643-.649,5.613,5.613,0,0,0,2.57-1.974,5.439,5.439,0,0,0,.941-3.232,5.379,5.379,0,0,0-.941-3.219,5.647,5.647,0,0,0-2.57-1.961,9.768,9.768,0,0,0-3.643-.649,9.843,9.843,0,0,0-3.669.649,5.782,5.782,0,0,0-2.6,1.974,5.289,5.289,0,0,0-.967,3.206,5.274,5.274,0,0,0,.967,3.219,5.909,5.909,0,0,0,2.6,1.974A9.669,9.669,0,0,0,12.32,23.3Zm26.256,2.7-4.848-6.279-4.981,6.279H24.455l7.312-8.717L24.72,8.864h4.292l4.8,6.014,4.875-6.014h4.292l-7.207,8.4,7.286,8.743ZM64.091,8.864l-7.5,17.142h-4.4L44.617,8.864h3.895l5.882,14.069L60.249,8.864ZM77.338,22.112H68.515l-1.749,3.895H62.951L71.006,8.864h3.815l8.134,17.142H79.113Zm-1.192-2.623-3.232-7.074-3.206,7.074Zm24.216,3.577v2.941H85.445V8.864h3.524v14.2Zm22.1-14.2V18.8q0,4-2.649,5.776a12.26,12.26,0,0,1-6.942,1.775,12.393,12.393,0,0,1-7.048-1.775Q103.2,22.8,103.2,18.8V8.864h3.524v9.565q0,2.782,1.576,3.815a8.358,8.358,0,0,0,4.57,1.033,8.182,8.182,0,0,0,4.491-1.033q1.576-1.033,1.576-3.815V8.864Zm20.825,14.2v2.941H126.538V8.864h16.718v2.941H130.062v4.557h10.81v2.2h-10.81v4.5Z",
                      transform: "translate(-1.484 -8.52)",
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mainSB-NewV-Btn", on: { click: _vm.goToLicense } },
            [
              _c("div", { staticClass: "NewV-btn-Child" }, [
                _c(
                  "div",
                  { staticStyle: { "font-weight": "100", "margin-left": "0" } },
                  [_vm._v("+")]
                ),
                _vm._v(" "),
                _vm.isLangEn == true
                  ? _c("div", {}, [_vm._v("New Valuation")])
                  : _c("div", {}, [
                      _vm._v("\n            新  的  估  值\n          "),
                    ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mainSB-LnS-Btn" }, [
            _c(
              "div",
              { staticClass: "btn-License", on: { click: _vm.goToLicense } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "18",
                      height: "18",
                      viewBox: "0 0 18 18",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_1160",
                        "data-name": "Path 1160",
                        d: "M13.5,16.875h3.375m0,0H20.25m-3.375,0V13.5m0,3.375V20.25M6,10.5H8.25A2.25,2.25,0,0,0,10.5,8.25V6A2.25,2.25,0,0,0,8.25,3.75H6A2.25,2.25,0,0,0,3.75,6V8.25A2.25,2.25,0,0,0,6,10.5Zm0,9.75H8.25A2.25,2.25,0,0,0,10.5,18V15.75A2.25,2.25,0,0,0,8.25,13.5H6a2.25,2.25,0,0,0-2.25,2.25V18A2.25,2.25,0,0,0,6,20.25Zm9.75-9.75H18a2.25,2.25,0,0,0,2.25-2.25V6A2.25,2.25,0,0,0,18,3.75H15.75A2.25,2.25,0,0,0,13.5,6V8.25A2.25,2.25,0,0,0,15.75,10.5Z",
                        transform: "translate(-3 -3)",
                        fill: "none",
                        stroke: "#000",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "1.5",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.isLangEn == true
                  ? _c("div", [_vm._v("License")])
                  : _c("div", [_vm._v("估值许可")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-Sub", on: { click: _vm.goToSubmission } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "21",
                      viewBox: "0 0 21 21",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_1161",
                        "data-name": "Path 1161",
                        d: "M6.429,9.75,2.25,12l4.179,2.25m0-4.5,5.571,3,5.571-3m-11.142,0L2.25,7.5,12,2.25,21.75,7.5,17.571,9.75m0,0L21.75,12l-4.179,2.25m0,0L21.75,16.5,12,21.75,2.25,16.5l4.179-2.25m11.142,0L12,17.25l-5.571-3",
                        transform: "translate(-1.5 -1.5)",
                        fill: "none",
                        stroke: "#000",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "1.5",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.isLangEn == true
                  ? _c("div", [_vm._v("Submission")])
                  : _c("div", [_vm._v("估值记录")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mainSB-history" }, [
            _vm.isLangEn == true
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      color: "rgb(125, 125, 126)",
                      "font-size": "1.4rem",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v("\n          History Case\n        ")]
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      color: "rgb(125, 125, 126)",
                      "font-size": "1.4rem",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v("\n          历史估值案例\n        ")]
                ),
            _vm._v(" "),
            _vm.isLangEn == true
              ? _c(
                  "div",
                  { staticClass: "mainSB-history-content" },
                  _vm._l(_vm.questionnaires, function (qnr, index) {
                    return _c(
                      "el-button",
                      {
                        key: index,
                        staticStyle: {
                          "white-space": "normal",
                          margin: "0px",
                          border: "0px",
                          "text-align": "left",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goToChat(qnr)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            Case " +
                            _vm._s(qnr.id) +
                            "\n            "
                        ),
                        qnr.description !== "" &&
                        JSON.parse(qnr.description)["companyName"]
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "5px" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      JSON.parse(qnr.description)["companyName"]
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "5px",
                              "font-size": "12px",
                              color: "grey",
                              "line-height": "1.25",
                            },
                          },
                          [
                            _vm._v(
                              "\n              Type: " +
                                _vm._s(qnr.company_type_name) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              Industry: " +
                                _vm._s(qnr.industry_name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "div",
                  { staticClass: "mainSB-history-content" },
                  _vm._l(_vm.questionnaires, function (qnr, index) {
                    return _c(
                      "el-button",
                      {
                        key: index,
                        staticStyle: {
                          "white-space": "normal",
                          margin: "0px",
                          border: "0px",
                          "text-align": "left",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goToChat(qnr)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            估值案例 " +
                            _vm._s(qnr.id) +
                            "\n            "
                        ),
                        qnr.description !== "" &&
                        JSON.parse(qnr.description)["companyName"]
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "5px" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      JSON.parse(qnr.description)["companyName"]
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "5px",
                              "font-size": "12px",
                              color: "grey",
                              "line-height": "1.25",
                            },
                          },
                          [
                            _vm._v(
                              "\n              类型: " +
                                _vm._s(qnr.company_type_name_zh) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              行业: " +
                                _vm._s(qnr.industry_name_zh) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mainSb-UserCard" }, [
            _c("div", { staticClass: "svgUserImg" }),
            _vm._v(" "),
            _c("div", { staticClass: "mainSB-UserName" }, [
              _vm._v(_vm._s(_vm.user_email.replace("@", "\n@"))),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "main-part" }, [
        _c("div", {
          class: { mainChatGrey: _vm.isMainChatGrey },
          on: { click: _vm.hideSidebars },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-part-nav" }, [
          _c(
            "div",
            {
              staticClass: "main-part-nav-text",
              on: { click: _vm.showSidebar },
            },
            [
              _vm.isLangEn == true
                ? _c("div", [_vm._v("Dashboard / ")])
                : _c("div", [_vm._v("仪表盘 / ")]),
              _vm._v(" "),
              _vm.isLangEn == true
                ? _c("div", { staticStyle: { color: "rgb(104, 112, 124)" } }, [
                    _vm._v("\n            License\n          "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isLangEn == false
                ? _c("div", { staticStyle: { color: "rgb(104, 112, 124)" } }, [
                    _vm._v("\n            估值许可\n          "),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "langBtn", staticStyle: { "margin-right": "2rem" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: _vm.buttonSize },
                  on: { click: _vm.showLanguageDialog },
                },
                [_vm._v("\n            Language / 语言")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "main-part-form" }, [
          _c(
            "div",
            { staticClass: "main-part-form-wrap" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    "highlight-current-row": "",
                    data: _vm.users,
                    height: "100%",
                    "row-class-name": _vm.tableRowClassName,
                  },
                },
                [
                  _vm.isLangEn
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "company_type_name",
                          label: "Company Type",
                          sortable: "",
                          width: "150",
                        },
                      })
                    : _c("el-table-column", {
                        attrs: {
                          prop: "company_type_name_zh",
                          label: "公司类型",
                          sortable: "",
                          width: "150",
                        },
                      }),
                  _vm._v(" "),
                  _vm.isLangEn
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "industry_name",
                          label: "Industry",
                          sortable: "",
                          width: "190",
                        },
                      })
                    : _c("el-table-column", {
                        attrs: {
                          prop: "industry_name_zh",
                          label: "行业",
                          sortable: "",
                          width: "190",
                        },
                      }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "balance",
                      label: _vm.isLangEn ? "Balance" : "估值次数",
                      sortable: "",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.platform_id == "2"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.isLangEn ? "Unlimited" : "不限")
                                  ),
                                ])
                              : _c("span", [_vm._v(_vm._s(scope.row.balance))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.isLangEn
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "free_balance",
                          label: "Balance(Free)",
                          sortable: "",
                          width: "150",
                        },
                      })
                    : _c("el-table-column", {
                        attrs: {
                          prop: "free_balance",
                          label: "估值次数(免费)",
                          sortable: "",
                          width: "150",
                        },
                      }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.isLangEn ? "Operation" : "操作",
                      width: "110",
                      fixed: "left",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.balance > 0 ||
                            scope.row.free_balance > 0 ||
                            _vm.platform_id == "2"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      disabled:
                                        scope.row.fk_questionnaire_id === "",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openBalDialog(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isLangEn ? "New Case" : "新的案例"
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.langDialogVisible,
            width: _vm.dialogSize,
          },
          on: {
            "update:visible": function ($event) {
              _vm.langDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please choose a language!")]),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v("请选择语言!")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setLangEn } },
                [_vm._v("English")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setLangZh } },
                [_vm._v("中文")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.langDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isLangEn ? "License Type" : "估值许可类型",
            visible: _vm.balanceTypeDialogVisible,
            width: _vm.dialogSize,
          },
          on: {
            "update:visible": function ($event) {
              _vm.balanceTypeDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.isLangEn
                  ? "Please select the type of licence to be used for this\n        case valuation:\n        " +
                      (_vm.platform_id == "2"
                        ? "Pay As You Go"
                        : "Paid license") +
                      " (using balance)/ Free licence (using free balance)"
                  : "请选择本次估值案例需要使用的许可类型:\n        付费许可(消耗估值次数)/免费许可(消耗免费估值次数)"
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "1.5rem" } },
            [
              _vm.isLangEn
                ? _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.termsChecked,
                        callback: function ($$v) {
                          _vm.termsChecked = $$v
                        },
                        expression: "termsChecked",
                      },
                    },
                    [
                      _vm._v("\n          I have read and agreed\n          "),
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "blue",
                            "text-decoration": "underline",
                          },
                          attrs: { href: "/terms", target: "_blank" },
                        },
                        [_vm._v("Terms and Conditions")]
                      ),
                      _vm._v("\n          and\n          "),
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "blue",
                            "text-decoration": "underline",
                          },
                          attrs: { href: "/privacy", target: "_blank" },
                        },
                        [_vm._v("Privacy Policy")]
                      ),
                    ]
                  )
                : _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.termsChecked,
                        callback: function ($$v) {
                          _vm.termsChecked = $$v
                        },
                        expression: "termsChecked",
                      },
                    },
                    [
                      _vm._v("\n          我已阅读并同意\n          "),
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "blue",
                            "text-decoration": "underline",
                          },
                          attrs: { href: "/terms", target: "_blank" },
                        },
                        [_vm._v("服务协议")]
                      ),
                      _vm._v("\n          和\n          "),
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "blue",
                            "text-decoration": "underline",
                          },
                          attrs: { href: "/privacy", target: "_blank" },
                        },
                        [_vm._v("隐私权政策")]
                      ),
                    ]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.platform_id != "2"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.paidBalBtnDisabled,
                      },
                      on: { click: _vm.choosePaidBalance },
                    },
                    [_vm._v(_vm._s(_vm.isLangEn ? "Paid License" : "付费许可"))]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.choosePaidBalance },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.isLangEn ? "Pay As You Go" : "付费估值")
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.freeBalBtnDisabled },
                  on: { click: _vm.chooseFreeBalance },
                },
                [_vm._v(_vm._s(_vm.isLangEn ? "Free License" : "免费许可"))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.balanceTypeDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.isLangEn ? "Cancel" : "取消"))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }