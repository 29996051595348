var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "解锁",
        visible: _vm.visible,
        width: _vm.dialogWidth,
        center: "",
        "append-to-body": "",
        "custom-class": "unlock-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "unlock-dialog-content" }, [
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                "您将付费解锁报告和证书(提交ID：" + _vm.selectedRow.id + ")"
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "price-info" }, [
          _c("div", [
            _c("span", { staticClass: "label" }, [_vm._v("价格：")]),
            _vm._v(" "),
            _c("span", { staticClass: "amount" }, [
              _vm._v(_vm._s(_vm.currentItem.price) + " GBP"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", { staticClass: "label" }, [_vm._v("折扣：")]),
            _vm._v(" "),
            _c("span", { staticClass: "amount" }, [
              _vm._v(_vm._s(_vm.currentItem.discount) + " GBP"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", { staticClass: "label" }, [_vm._v("VAT：")]),
            _vm._v(" "),
            _c("span", { staticClass: "amount" }, [
              _vm._v(_vm._s(_vm.currentItem.vat) + " GBP"),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "price-to-pay" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v("\n        需支付：\n      "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "amount bold" }, [
            _vm._v(_vm._s(_vm.currentItem.price_to_pay) + " GBP"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("confirm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }