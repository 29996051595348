"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    row: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      termsChecked: false
    };
  },
  methods: {
    choosePaidBalance: function choosePaidBalance() {
      console.log("paid");
      if (this.termsChecked == false) {
        this.$message.error("请阅读并同意服务协议和隐私权政策!");
        return;
      }
      this.gotoChatbot(this.row, "paid");
    },
    chooseFreeBalance: function chooseFreeBalance() {
      console.log("free");
      if (this.termsChecked == false) {
        this.$message.error("请阅读并同意服务协议和隐私权政策!");
        return;
      }
      this.gotoChatbot(this.row, "free");
    },
    gotoChatbot: function gotoChatbot(row, bal) {
      console.log(row);
      var pid = row.fk_questionnaire_id;
      var lid = row.id;

      // 去中文chatbot
      window.open("https://eva.oxvalue.ai/chatbot_zh?pid=" + pid + "&lid=" + lid + "&bal=".concat(bal), "_self");
      this.dialogVisible = false;
    },
    closeDialog: function closeDialog() {
      this.termsChecked = false;
      this.$emit("update:visible", false);
    }
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(value) {
        this.$emit("update:visible", value);
      }
    }
  }
};
exports.default = _default;