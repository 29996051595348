"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Icon = _interopRequireDefault(require("./Icon.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Icon: _Icon.default
  },
  data: function data() {
    return {
      user_email: "",
      isMobile: false
    };
  },
  created: function created() {
    this.user_email = window.localStorage.getItem("user_email");
  },
  mounted: function mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize: function checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
exports.default = _default;