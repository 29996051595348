"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _en2 = _interopRequireDefault(require("./en"));
var _zh = _interopRequireDefault(require("./zh"));
// element-ui lang
// element-ui lang

_vue.default.use(_vueI18n.default);
var messages = {
  en: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _en2.default), _en.default),
  zh: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _zh.default), _zhCN.default)
};
var i18n = new _vueI18n.default({
  locale: _jsCookie.default.get('language') || 'zh',
  // set locale
  messages: messages // set locale messages
});
var _default = i18n;
exports.default = _default;