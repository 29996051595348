"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // 导航栏
  navbar: {
    title: '自动化管理系统',
    languageSwitch: '语言切换',
    theme: '主题'
  },
  skin: {
    Blue: '天空蓝',
    Green: '典雅绿',
    Red: '樱桃红',
    Purple: '贵族紫',
    Default: '默认'
  },
  route: {
    AliIcons: '阿里图标',
    contextmenu: '右键菜单',
    simple: '基础',
    divier: '分割线',
    group: '按钮组',
    submenu: '子菜单',
    disabled: '禁用',
    customtrigger: '自定义事件',
    form: '表单',
    Tabs: 'tabs选项卡',
    Table: '表格',
    Icons: 'icon图标',
    treeChart: '树图',
    gaugeChart: '仪表图',
    candleChart: 'K线图',
    scatterChart: '散力图',
    heatmapChart: '热力图',
    sankeyChart: '桑基图',
    barGraph: '条形图',
    pieChart: '周数据',
    ringChart: '环图',
    waterfallCharts: '瀑布图',
    funnelCharts: '漏斗图',
    radarCharts: '雷达图',
    vueActual: 'vue实战',
    from: '表单',
    BaseForm: '付款充值',
    VueEditor: '文本编辑',
    Upload: '文件上传',
    Components: '组件',
    dragKanban: '可拖拽看板',
    componentMixin: 'componentMixin',
    markdown: 'Markdown',
    treeMen: '树形菜单',
    backToTop: '返回顶部',
    dashboard: '首页',
    DiscountFigure: '折线图',
    columnar: '月数据',
    treeMenu: '树形菜单',
    wel: '动态文字',
    Lockscreenpage: '锁屏页',
    countenance: '表情',
    clipboard: '复制',
    Chartmember: '图表成员',
    permission: '权限测试页',
    pagePermission: '页面权限',
    directivePermission: '按钮权限',
    complexTable: '门店信息',
    treeTable: '树形表格',
    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    thirdPartyWebsite: 'Trinal Genius 官网',
    charts: 'Charts',
    emoji: 'emoji',
    test: 'test',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    exportZip: 'Export Zip',
    i18n: '国际化',
    PDF: 'PDF',
    HightLightinViewer: '代码高亮显示',
    Rollingpositioning: '滚动定位',
    Digitalanimation: '数字动画',
    list: 'list',
    Errorlog: '错误日志',
    svgicons: 'svg图标',
    fontawesome: 'fontawesome图标',
    pointMap: '点聚合地图',
    roadBookMap: '路书地图',
    cityLlistMap: '城市列表地图',
    baidumap: '百度地图',
    gpsMap: 'gps定位'
  },
  permission: {
    roles: '你的权限',
    switchRoles: '切换权限'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  componentss: {
    documentation: '文档',
    tinymceTips: '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    dropzoneTips: '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
    stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
  },
  tagsView: {
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  }
};
exports.default = _default;