var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container pull-height",
      nativeOn: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleLogin($event)
        },
      },
    },
    [
      _c("div", { staticClass: "logo" }),
      _vm._v(" "),
      _c("div", { staticClass: "login-border " }, [
        _c("div", { staticClass: "login-title" }, [
          _vm._v("\n      Login OxValue.AI\n    "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "login-main" }, [_c("codeLogin")], 1),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }