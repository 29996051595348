"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  language: function language(state) {
    return state.app.language;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  website: function website(state) {
    return state.common.website;
  },
  isLock: function isLock(state) {
    return state.user.isLock;
  },
  lockPasswd: function lockPasswd(state) {
    return state.user.lockPasswd;
  },
  isFullScren: function isFullScren(state) {
    return state.common.isFullScren;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  browserHeaderTitle: function browserHeaderTitle(state) {
    return state.user.browserHeaderTitle;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  }
};
var _default = getters;
exports.default = _default;