var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "981px" },
          attrs: { data: _vm.users, "highlight-current-row": "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "company_type_name",
              label: "Company Type",
              sortable: "",
              width: "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "industry_name",
              label: "Industry",
              sortable: "",
              width: "390",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "balance",
              label: "Balance",
              sortable: "",
              width: "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Operation", width: "110", fixed: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.balance > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoChatbot(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("New Chat")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please choose a language!")]),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v("请选择语言!")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.goToEnChatbot },
                },
                [_vm._v("English")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.goToZhChatbot },
                },
                [_vm._v("中文")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }