"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Page401',
  data: function data() {
    return {
      ewizardClap: 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646',
      dialogVisible: false
    };
  },
  methods: {
    back: function back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({
          path: '/dashboard'
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
exports.default = _default;