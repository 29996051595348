"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _mockjs = _interopRequireDefault(require("mockjs"));
var _utils = require("@/utils");
var List = [];
var count = 100;
var baseContent = '<p>我是测试数据我是测试数据</p><p><img src="https://wpimg.wallstcn.com/4c69009c-0fd4-4153-b112-6cb53d1cf943"></p>';
var image_uri = 'https://wpimg.wallstcn.com/e4558086-631c-425c-9430-56ffb46e70b3';
for (var i = 0; i < count; i++) {
  List.push(_mockjs.default.mock({
    id: '@increment',
    timestamp: +_mockjs.default.Random.date('T'),
    author: '@first',
    reviewer: '@first',
    title: '@title(5, 10)',
    content_short: '我是测试数据',
    content: baseContent,
    forecast: '@float(0, 100, 2, 2)',
    importance: '@integer(1, 3)',
    'type|1': ['CN', 'US', 'JP', 'EU'],
    'status|1': ['published', 'draft', 'deleted'],
    display_time: '@datetime',
    comment_disabled: true,
    pageviews: '@integer(300, 5000)',
    image_uri: image_uri,
    platforms: ['a-platform']
  }));
}
var _default = {
  getList: function getList(config) {
    var _param2Obj = (0, _utils.param2Obj)(config.url),
      importance = _param2Obj.importance,
      type = _param2Obj.type,
      title = _param2Obj.title,
      _param2Obj$page = _param2Obj.page,
      page = _param2Obj$page === void 0 ? 1 : _param2Obj$page,
      _param2Obj$limit = _param2Obj.limit,
      limit = _param2Obj$limit === void 0 ? 20 : _param2Obj$limit,
      sort = _param2Obj.sort;
    var mockList = List.filter(function (item) {
      if (importance && item.importance !== +importance) return false;
      if (type && item.type !== type) return false;
      if (title && item.title.indexOf(title) < 0) return false;
      return true;
    });
    if (sort === '-id') {
      mockList = mockList.reverse();
    }
    var pageList = mockList.filter(function (item, index) {
      return index < limit * page && index >= limit * (page - 1);
    });
    return {
      code: 0,
      data: {
        total: mockList.length,
        // users: pageList,
        items: pageList
      }
    };
  },
  getPv: function getPv() {
    return {
      pvData: [{
        key: 'PC',
        pv: 1024
      }, {
        key: 'mobile',
        pv: 1024
      }, {
        key: 'ios',
        pv: 1024
      }, {
        key: 'android',
        pv: 1024
      }]
    };
  },
  getArticle: function getArticle(config) {
    var _param2Obj2 = (0, _utils.param2Obj)(config.url),
      id = _param2Obj2.id;
    for (var _i = 0, _List = List; _i < _List.length; _i++) {
      var article = _List[_i];
      if (article.id === +id) {
        return article;
      }
    }
  },
  createArticle: function createArticle() {
    return {
      data: 'success'
    };
  },
  updateArticle: function updateArticle() {
    return {
      data: 'success'
    };
  }
};
exports.default = _default;