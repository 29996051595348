var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mainContainer" }, [
    _c("div", { staticClass: "title" }, [_vm._v("仪表板/估值记录")]),
    _vm._v(" "),
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "navBar" },
        [
          _c("div", { staticClass: "tittle-all" }, [_vm._v("全部")]),
          _vm._v(" "),
          _vm._l(
            Object.keys(_vm.groupedSubmissionList),
            function (item, index) {
              return _c(
                "div",
                { key: index },
                [_c("NavItem", { attrs: { industry: item } })],
                1
              )
            }
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("SubmissionTableCard", {
            attrs: { tableData: _vm.users, platform_id: _vm.platform_id },
            on: {
              "update:tableData": function ($event) {
                _vm.users = $event
              },
              "update:table-data": function ($event) {
                _vm.users = $event
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }