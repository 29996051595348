var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "估值许可类型",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("span", { staticClass: "content" }, [
        _vm._v(
          "\n    请选择本次估值案例需要使用的许可类型:\n    付费许可(消耗估值次数)/免费许可(消耗免费估值次数)\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "terms" },
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.termsChecked,
                callback: function ($$v) {
                  _vm.termsChecked = $$v
                },
                expression: "termsChecked",
              },
            },
            [
              _vm._v("\n      我已阅读并同意\n      "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "blue",
                    "text-decoration": "underline",
                  },
                  attrs: { href: "/terms", target: "_blank" },
                },
                [_vm._v("服务协议")]
              ),
              _vm._v("\n      和\n      "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "blue",
                    "text-decoration": "underline",
                  },
                  attrs: { href: "/privacy", target: "_blank" },
                },
                [_vm._v("隐私权政策")]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "btnBalance",
              attrs: { disabled: _vm.row.balance == 0 ? true : false },
              on: { click: _vm.choosePaidBalance },
            },
            [_vm._v("付费许可")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btnFreeBalance",
              attrs: { disabled: _vm.row.free_balance == 0 ? true : false },
              on: { click: _vm.chooseFreeBalance },
            },
            [_vm._v("免费许可")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }