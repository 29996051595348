var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        {
          staticClass: "eldropdown",
          attrs: { trigger: "hover" },
          on: { command: _vm.handleCommandSkin },
        },
        [
          _c("span", { staticClass: "el-dropdown-link lang" }, [
            _vm._v(_vm._s(_vm.$t("navbar.theme"))),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.skins, function (item, index) {
              return _c(
                "el-dropdown-item",
                { key: index, attrs: { command: item.name } },
                [_vm._v(_vm._s(_vm.generateSkinColor(item.label)))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }