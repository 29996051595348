"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ChatlogCard = _interopRequireDefault(require("./ChatlogCard.vue"));
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ChatlogCard: _ChatlogCard.default
  },
  data: function data() {
    return {
      questionnaires: []
    };
  },
  created: function created() {
    var _this = this;
    (0, _axios.default)({
      method: "get",
      url: "https://api.oxvalue.ai:7999/api/chat/v0.1/chat_log/",
      headers: {
        Authorization: window.localStorage.getItem("ova_user_token")
      }
    }).then(function (res) {
      _this.questionnaires = res.data.data.chat_log_list;
      console.log(_this.questionnaires);
    });
    return;
  },
  methods: {
    company_name: function company_name(qnr) {
      return qnr.description !== "" && JSON.parse(qnr.description)["companyName"] ? JSON.parse(qnr.description)["companyName"] : "";
    },
    goToChat: function goToChat(qnr) {
      if (qnr.description == "" || JSON.parse(qnr.description).language == "en") {
        window.open("https://eva.oxvalue.ai/chatbot?pid=".concat(qnr.fk_questionnaire_id, "&lid=").concat(qnr.fk_user_license_id, "&cid=").concat(qnr.id), "_self");
      } else if (JSON.parse(qnr.description).language == "zh") {
        window.open("https://eva.oxvalue.ai/chatbot_zh?pid=".concat(qnr.fk_questionnaire_id, "&lid=").concat(qnr.fk_user_license_id, "&cid=").concat(qnr.id), "_self");
      }
    }
  }
};
exports.default = _default;