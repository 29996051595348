var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bigBox" },
    [
      _c(
        "div",
        {
          staticClass: "mainSideBar",
          class: { mainSBinMobil: _vm.isMianSidebarOpenSmall },
        },
        [
          _c("div", { staticClass: "mainSBLogo" }, [
            _c("div", { staticClass: "logo" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "141.799",
                    height: "17.831",
                    viewBox: "0 0 141.799 17.831",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_1173",
                      "data-name": "Path 1173",
                      d: "M12.32,26.351a14.559,14.559,0,0,1-5.829-1.06,8.136,8.136,0,0,1-3.722-3.06,8.658,8.658,0,0,1-1.285-4.782,8.724,8.724,0,0,1,1.285-4.8A8.107,8.107,0,0,1,6.491,9.58,14.559,14.559,0,0,1,12.32,8.52a14.537,14.537,0,0,1,5.842,1.06,8.116,8.116,0,0,1,3.709,3.073,8.724,8.724,0,0,1,1.285,4.8,8.658,8.658,0,0,1-1.285,4.782,8.136,8.136,0,0,1-3.722,3.06A14.559,14.559,0,0,1,12.32,26.351Zm0-3.047a9.767,9.767,0,0,0,3.643-.649,5.613,5.613,0,0,0,2.57-1.974,5.439,5.439,0,0,0,.941-3.232,5.379,5.379,0,0,0-.941-3.219,5.647,5.647,0,0,0-2.57-1.961,9.768,9.768,0,0,0-3.643-.649,9.843,9.843,0,0,0-3.669.649,5.782,5.782,0,0,0-2.6,1.974,5.289,5.289,0,0,0-.967,3.206,5.274,5.274,0,0,0,.967,3.219,5.909,5.909,0,0,0,2.6,1.974A9.669,9.669,0,0,0,12.32,23.3Zm26.256,2.7-4.848-6.279-4.981,6.279H24.455l7.312-8.717L24.72,8.864h4.292l4.8,6.014,4.875-6.014h4.292l-7.207,8.4,7.286,8.743ZM64.091,8.864l-7.5,17.142h-4.4L44.617,8.864h3.895l5.882,14.069L60.249,8.864ZM77.338,22.112H68.515l-1.749,3.895H62.951L71.006,8.864h3.815l8.134,17.142H79.113Zm-1.192-2.623-3.232-7.074-3.206,7.074Zm24.216,3.577v2.941H85.445V8.864h3.524v14.2Zm22.1-14.2V18.8q0,4-2.649,5.776a12.26,12.26,0,0,1-6.942,1.775,12.393,12.393,0,0,1-7.048-1.775Q103.2,22.8,103.2,18.8V8.864h3.524v9.565q0,2.782,1.576,3.815a8.358,8.358,0,0,0,4.57,1.033,8.182,8.182,0,0,0,4.491-1.033q1.576-1.033,1.576-3.815V8.864Zm20.825,14.2v2.941H126.538V8.864h16.718v2.941H130.062v4.557h10.81v2.2h-10.81v4.5Z",
                      transform: "translate(-1.484 -8.52)",
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mainSB-NewV-Btn", on: { click: _vm.goToLicense } },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mainSB-LnS-Btn" }, [
            _c(
              "div",
              { staticClass: "btn-License", on: { click: _vm.goToLicense } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "18",
                      height: "18",
                      viewBox: "0 0 18 18",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_1160",
                        "data-name": "Path 1160",
                        d: "M13.5,16.875h3.375m0,0H20.25m-3.375,0V13.5m0,3.375V20.25M6,10.5H8.25A2.25,2.25,0,0,0,10.5,8.25V6A2.25,2.25,0,0,0,8.25,3.75H6A2.25,2.25,0,0,0,3.75,6V8.25A2.25,2.25,0,0,0,6,10.5Zm0,9.75H8.25A2.25,2.25,0,0,0,10.5,18V15.75A2.25,2.25,0,0,0,8.25,13.5H6a2.25,2.25,0,0,0-2.25,2.25V18A2.25,2.25,0,0,0,6,20.25Zm9.75-9.75H18a2.25,2.25,0,0,0,2.25-2.25V6A2.25,2.25,0,0,0,18,3.75H15.75A2.25,2.25,0,0,0,13.5,6V8.25A2.25,2.25,0,0,0,15.75,10.5Z",
                        transform: "translate(-3 -3)",
                        fill: "none",
                        stroke: "#000",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "1.5",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", [_vm._v("估值许可")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-Sub", on: { click: _vm.goToSubmission } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "21",
                      viewBox: "0 0 21 21",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_1161",
                        "data-name": "Path 1161",
                        d: "M6.429,9.75,2.25,12l4.179,2.25m0-4.5,5.571,3,5.571-3m-11.142,0L2.25,7.5,12,2.25,21.75,7.5,17.571,9.75m0,0L21.75,12l-4.179,2.25m0,0L21.75,16.5,12,21.75,2.25,16.5l4.179-2.25m11.142,0L12,17.25l-5.571-3",
                        transform: "translate(-1.5 -1.5)",
                        fill: "none",
                        stroke: "#000",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "1.5",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", [_vm._v("估值记录")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mainSB-history" }, [
            _c(
              "div",
              {
                staticStyle: {
                  color: "rgb(125, 125, 126)",
                  "font-size": "1.4rem",
                  "font-weight": "600",
                },
              },
              [_vm._v("\n        历史估值案例\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mainSB-history-content" },
              _vm._l(_vm.questionnaires, function (qnr, index) {
                return _c(
                  "el-button",
                  {
                    key: index,
                    staticStyle: {
                      "white-space": "normal",
                      margin: "0px",
                      border: "0px",
                      "text-align": "left",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.goToChat(qnr)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          估值案例 " + _vm._s(qnr.id) + "\n          "
                    ),
                    qnr.description !== "" &&
                    JSON.parse(qnr.description)["companyName"]
                      ? _c("div", { staticStyle: { "margin-top": "5px" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                JSON.parse(qnr.description)["companyName"]
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "5px",
                          "font-size": "12px",
                          color: "grey",
                          "line-height": "1.25",
                        },
                      },
                      [
                        _vm._v(
                          "\n            类型: " +
                            _vm._s(qnr.company_type_name_zh) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            行业: " +
                            _vm._s(qnr.industry_name_zh) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mainSb-UserCard" }, [
            _c("div", { staticClass: "svgUserImg" }),
            _vm._v(" "),
            _c("div", { staticClass: "mainSB-UserName" }, [
              _vm._v(_vm._s(_vm.user_email.replace("@", "\n@"))),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isSnSidebarOpen
        ? _c(
            "div",
            { staticClass: "secondSideBar", class: { notShow: _vm.SnNotShow } },
            [
              _c("div", { staticClass: "sidebar-btn" }, [
                _c("button", {
                  staticClass: "icon-back",
                  on: { click: _vm.goToMainSideBar },
                }),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "sidebar-content-qnnaireInfo",
                  staticStyle: { "margin-left": "3rem" },
                },
                [
                  _c("div", [
                    _vm._v("问卷ID: " + _vm._s(_vm.questionnaire_id)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("问卷版本: " + _vm._s(_vm.questionnaire_version)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sidebar-content" },
                _vm._l(_vm.questions, function (qn, index) {
                  return !qn.is_subquestion && !qn.is_hidden
                    ? _c(
                        "div",
                        {
                          key: qn.index,
                          staticClass: "sidebar-content-question",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "sidebar-content-question-checkBox",
                            },
                            [
                              _c("el-checkbox", {
                                attrs: { size: "medium" },
                                model: {
                                  value:
                                    qn.answers.length > 0 &&
                                    qn.answers[0] != "Not Answered",
                                  callback: function ($$v) {
                                    _vm.$set(
                                      qn.answers.length > 0 && qn,
                                      "answers[0] != 'Not Answered'",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "qn.answers.length > 0 && qn.answers[0] != 'Not Answered'",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "sidebar-content-question-content" },
                            [
                              _vm.languageIsEn
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "sidebar-content-question-content-QnDescription",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(qn.question_en) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "sidebar-content-question-content-QnDescription",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(qn.question_zh) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mask" }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mainChat" },
        [
          _c("div", {
            class: { mainChatGrey: _vm.isMainChatGrey },
            on: { click: _vm.hideSidebars },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mainChatBackBtnDiv" }, [
            _c(
              "div",
              { staticStyle: { "line-height": "1.60", "font-size": "1.5rem" } },
              [
                _c("div", [
                  _vm._v("类型: " + _vm._s(_vm.company_type_name_zh)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("行业: " + _vm._s(_vm.industry_name_zh))]),
              ]
            ),
            _vm._v(" "),
            _c("button", {
              staticClass: "icon-back",
              on: { click: _vm.goToSeconSideBar },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "chat", staticClass: "chat-history" },
            [
              _vm._l(_vm.messageList, function (msg, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: { noGrey: true, addGrey: msg.gpt_mode },
                    staticStyle: {
                      display: "flex",
                      width: "100%",
                      padding: "1rem 1.5rem",
                    },
                  },
                  [
                    _c("div", {
                      class: {
                        avatar: msg.role == "OxValue.AI",
                        avatarAI: msg.gpt_mode,
                        avatarUser: msg.role == "You",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "message" }, [
                      _c("div", { staticClass: "username" }, [
                        _vm._v(_vm._s(msg.role)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(msg.content)),
                      ]),
                      _vm._v(" "),
                      msg.description && msg.description.trim()
                        ? _c("div", { staticClass: "description" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(msg.description) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      msg.multimodal_answers &&
                      msg.multimodal_answers.length > 0
                        ? _c(
                            "div",
                            { staticClass: "multimodal_answers" },
                            [
                              _vm._v(
                                "\n            AI智能识别答案：\n            "
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { disabled: _vm.disableButton },
                                  on: {
                                    click: function ($event) {
                                      _vm.fillAnswer(
                                        msg.multimodal_answers.join(", ")
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(msg.multimodal_answers.join(", "))
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "answer-options",
                  staticStyle: { "margin-top": "1%" },
                },
                [
                  _vm._l(_vm.choices, function (choice, index) {
                    return _c(
                      "el-button",
                      {
                        key: index,
                        class: { option_button_selected: choice.is_selected },
                        staticStyle: {
                          "font-size": "1.3rem",
                          "margin-left": "1%",
                          "margin-right": "1%",
                          "border-radius": "10px",
                          border: "2px solid #DCDFE6",
                          "white-space": "normal",
                        },
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.select(choice, index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(choice.name) + "\n        "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.choices.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            "font-size": "1.3rem",
                            "margin-left": "1%",
                            "margin-right": "1%",
                            "border-radius": "10px",
                            border: "2px solid #DCDFE6",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("\n          提交\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.submitted
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            "max-width": "60%",
                            "align-self": "center",
                            margin: "3%",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.goToSubmission },
                        },
                        [_vm._v("查看估值记录")]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.current_type == "address"
                ? _c(
                    "div",
                    { staticClass: "address-select" },
                    [
                      _vm.country_list.length > 0
                        ? _c(
                            "el-select",
                            {
                              ref: "countryRef",
                              staticStyle: { width: "25%" },
                              attrs: {
                                placeholder: "请选择国家",
                                filterable: "",
                              },
                              on: { change: _vm.get_region },
                              model: {
                                value: _vm.country,
                                callback: function ($$v) {
                                  _vm.country = $$v
                                },
                                expression: "country",
                              },
                            },
                            _vm._l(_vm.country_list, function (country, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: country.name_zh,
                                  value: country.id,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.region_list.length > 0 && _vm.country == 130
                        ? _c(
                            "el-select",
                            {
                              ref: "regionRef",
                              staticStyle: { width: "25%" },
                              attrs: {
                                placeholder: "请选择省份",
                                filterable: "",
                              },
                              on: { change: _vm.get_city },
                              model: {
                                value: _vm.region,
                                callback: function ($$v) {
                                  _vm.region = $$v
                                },
                                expression: "region",
                              },
                            },
                            _vm._l(_vm.region_list, function (region, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: region.name_zh,
                                  value: region.id,
                                },
                              })
                            }),
                            1
                          )
                        : _c(
                            "el-select",
                            {
                              ref: "regionRef",
                              staticStyle: { width: "25%" },
                              attrs: {
                                placeholder: "请选择省份",
                                filterable: "",
                              },
                              on: { change: _vm.get_city },
                              model: {
                                value: _vm.region,
                                callback: function ($$v) {
                                  _vm.region = $$v
                                },
                                expression: "region",
                              },
                            },
                            _vm._l(_vm.region_list, function (region, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: region.name, value: region.id },
                              })
                            }),
                            1
                          ),
                      _vm._v(" "),
                      _vm.city_list.length > 0
                        ? _c(
                            "el-select",
                            {
                              ref: "cityRef",
                              staticStyle: { width: "25%" },
                              attrs: { placeholder: "请选择城市" },
                              model: {
                                value: _vm.city,
                                callback: function ($$v) {
                                  _vm.city = $$v
                                },
                                expression: "city",
                              },
                            },
                            _vm._l(_vm.city_list, function (city, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: city.name, value: city.id },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.city.length > 0
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { width: "20%" },
                              on: { click: _vm.submitAddress },
                            },
                            [_vm._v("提交")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.currentQuestionIndex < _vm.questionList.length
            ? _c(
                "div",
                {
                  staticClass: "button-container",
                  attrs: { id: "button-container" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "font-size": "1.3rem",
                        color: "black",
                        "margin-right": "1%",
                        "border-radius": "10px",
                        border: "2px solid #DCDFE6",
                      },
                      attrs: { disabled: _vm.waiting, size: "small" },
                      on: { click: _vm.ask_meaning },
                    },
                    [_vm._v("解释问题")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "font-size": "1.3rem",
                        color: "black",
                        "margin-right": "1%",
                        "border-radius": "10px",
                        border: "2px solid #DCDFE6",
                      },
                      attrs: { disabled: _vm.waiting, size: "small" },
                      on: { click: _vm.ask_exmaple },
                    },
                    [_vm._v("给我一个例子")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "font-size": "1.3rem",
                        color: "black",
                        "margin-right": "1%",
                        "border-radius": "10px",
                        border: "2px solid #DCDFE6",
                      },
                      attrs: { disabled: _vm.waiting, size: "small" },
                      on: { click: _vm.skip },
                    },
                    [_vm._v("跳过这个问题")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "font-size": "1.3rem",
                        color: "black",
                        "margin-right": "1%",
                        "border-radius": "10px",
                        border: "2px solid #DCDFE6",
                      },
                      attrs: { disabled: _vm.waiting, size: "small" },
                      on: { click: _vm.showReview },
                    },
                    [_vm._v("专家模式")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "font-size": "1.3rem",
                        color: "black",
                        "margin-right": "1%",
                        "border-radius": "10px",
                        border: "2px solid #DCDFE6",
                      },
                      attrs: { disabled: _vm.waiting, size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.showMuiltiModeFileAnswers()
                        },
                      },
                    },
                    [_vm._v("多模态答案")]
                  ),
                  _vm._v(" "),
                  _c("AiValuation", {
                    staticStyle: { display: "none" },
                    attrs: {
                      questionList: _vm.questionList,
                      currency: _vm.currency,
                      cid: _vm.cid,
                      balanceType: _vm.balanceType,
                      user_questions: _vm.user_questions,
                    },
                    on: { "update:questionList": _vm.updateQuestionList },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentQuestionIndex < _vm.questionList.length
            ? _c(
                "div",
                {
                  staticClass: "user-input",
                  attrs: { id: "user-input" },
                  on: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.sendMessage($event)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                      },
                    ],
                  },
                },
                [
                  _c("button", {
                    class: { aiBtn: !_vm.gpt_mode, aiBtnSolid: _vm.gpt_mode },
                    attrs: { disabled: _vm.waiting },
                    on: { click: _vm.toggleChatGPTMode },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "inputBox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userInput,
                          expression: "userInput",
                        },
                      ],
                      attrs: {
                        id: "userInput",
                        disabled: _vm.userInput_disabled,
                        placeholder: _vm.userInput_placeholder,
                        rows: 1,
                        type: "text",
                      },
                      domProps: { value: _vm.userInput },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.userInput = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "send-button",
                      attrs: { disabled: _vm.userInput_disabled },
                      on: { click: _vm.sendMessage },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: {
                display: "None",
                "max-width": "80%",
                "align-self": "center",
                margin: "3%",
              },
              attrs: { id: "generate-report-button", type: "primary" },
              on: { click: _vm.submitToServer },
            },
            [_vm._v("Submit Answers and Generate Report")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: {
                display: "None",
                "max-width": "80%",
                "align-self": "center",
                margin: "3%",
              },
              attrs: { id: "review-button", type: "primary" },
              on: { click: _vm.review },
            },
            [_vm._v("回顾全部回答")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "回顾",
            visible: _vm.reviewDialogVisible,
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.reviewDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-container" },
            [
              _c(
                "el-form",
                {
                  ref: "reviewForm",
                  attrs: { model: _vm.reviewForm, rules: _vm.reviewRules },
                },
                [
                  _vm._l(_vm.questionList, function (question, index) {
                    return (_vm.multi_obj_subquestions.indexOf(
                      parseInt(index) + 1
                    ) == -1 &&
                      !question.is_subquestion &&
                      !question.is_hidden) ||
                      (_vm.patentQnsList.includes(question.name) &&
                        _vm.company_type_name == "PATENT")
                      ? _c(
                          "el-form-item",
                          [
                            _c("div", [
                              _vm._v(
                                "问题 " +
                                  _vm._s(index + 1) +
                                  ": " +
                                  _vm._s(question.question_zh)
                              ),
                            ]),
                            _vm._v(" "),
                            question.type == "multiple_object"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        attrs: {
                                          data: question.answers,
                                          border: "",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          question.choices["Yes"],
                                          function (column, columnIndex) {
                                            return _c("el-table-column", {
                                              key: columnIndex,
                                              staticStyle: {
                                                "word-break": "normal",
                                              },
                                              attrs: {
                                                label:
                                                  index +
                                                  1 +
                                                  columnIndex +
                                                  1 +
                                                  ": " +
                                                  _vm.questionList[column - 1]
                                                    .question_zh,
                                                width: "190%",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _vm.questionList[
                                                          column - 1
                                                        ].type ==
                                                        "single_choice"
                                                          ? [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      !_vm.editing,
                                                                    placeholder:
                                                                      "请选择",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.change,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      question
                                                                        .answers[
                                                                        scope
                                                                          .$index
                                                                      ][
                                                                        columnIndex
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          question
                                                                            .answers[
                                                                            scope
                                                                              .$index
                                                                          ],
                                                                          columnIndex,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "question.answers[scope.$index][columnIndex]",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  Object.keys(
                                                                    _vm
                                                                      .questionList[
                                                                      column - 1
                                                                    ].choices_zh
                                                                  ),
                                                                  function (
                                                                    choice,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          label:
                                                                            choice,
                                                                          value:
                                                                            choice,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.questionList[
                                                          column - 1
                                                        ].type ==
                                                        "multiple_choice"
                                                          ? [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请选择",
                                                                    disabled:
                                                                      !_vm.editing,
                                                                    multiple:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.change,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      question
                                                                        .answers[
                                                                        scope
                                                                          .$index
                                                                      ][
                                                                        columnIndex
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          question
                                                                            .answers[
                                                                            scope
                                                                              .$index
                                                                          ],
                                                                          columnIndex,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "question.answers[scope.$index][columnIndex]",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          _vm.choice,
                                                                        value:
                                                                          _vm.choice,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.questionList[
                                                          column - 1
                                                        ].choices_zh.length == 0
                                                          ? [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      question
                                                                        .answers[
                                                                        scope
                                                                          .$index
                                                                      ][
                                                                        columnIndex
                                                                      ],
                                                                    expression:
                                                                      "question.answers[scope.$index][columnIndex]",
                                                                  },
                                                                ],
                                                                domProps: {
                                                                  value:
                                                                    question
                                                                      .answers[
                                                                      scope
                                                                        .$index
                                                                    ][
                                                                      columnIndex
                                                                    ],
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        question
                                                                          .answers[
                                                                          scope
                                                                            .$index
                                                                        ],
                                                                        columnIndex,
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          staticStyle: {
                                            "overflow-wrap": "break-word",
                                          },
                                          attrs: {
                                            label: "操作",
                                            width: "100%",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                          icon: "el-icon-delete",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDelete(
                                                              question,
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdd(question)
                                          },
                                        },
                                      },
                                      [_vm._v("新增一行")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            Object.keys(question.choices_zh).length > 0 &&
                            question.type == "multiple_choice" &&
                            question.type != "multiple_object"
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: !_vm.editing,
                                      multiple: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.change(question)
                                      },
                                    },
                                    model: {
                                      value: question.answers,
                                      callback: function ($$v) {
                                        _vm.$set(question, "answers", $$v)
                                      },
                                      expression: "question.answers",
                                    },
                                  },
                                  _vm._l(
                                    Object.keys(question.choices_zh),
                                    function (choice, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: { label: choice, value: choice },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            Object.keys(question.choices_zh).length > 0 &&
                            question.type != "multiple_choice" &&
                            question.type != "multiple_object"
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: !_vm.editing,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.change(question)
                                      },
                                    },
                                    model: {
                                      value: question.answers[0],
                                      callback: function ($$v) {
                                        _vm.$set(question.answers, 0, $$v)
                                      },
                                      expression: "question.answers[0]",
                                    },
                                  },
                                  _vm._l(
                                    Object.keys(question.choices_zh),
                                    function (choice, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: { label: choice, value: choice },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            Object.keys(question.choices_zh).length == 0
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: question.answers[0],
                                      expression: "question.answers[0]",
                                    },
                                  ],
                                  attrs: {
                                    id: "review-input",
                                    disabled: !_vm.editing,
                                  },
                                  domProps: { value: question.answers[0] },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        question.answers,
                                        0,
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "估值报告货币单位", prop: "curr" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择货币单位" },
                          model: {
                            value: _vm.reviewForm.curr,
                            callback: function ($$v) {
                              _vm.$set(_vm.reviewForm, "curr", $$v)
                            },
                            expression: "reviewForm.curr",
                          },
                        },
                        _vm._l(_vm.curr_options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.submitBtnLoading,
                        disabled: _vm.submitBtnLoading,
                      },
                      on: { click: _vm.submitToServer },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择需要上传的多模态文件",
            visible: _vm.uploadDialogVisible,
            width: "50%",
            top: "10vh",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadDialogVisible = $event
            },
          },
        },
        [
          !_vm.isProcessing
            ? _c(
                "div",
                [
                  _c("MultiModalUploader", {
                    attrs: { fileIds: _vm.fileIds },
                    on: { "upload-complete": _vm.startPolling },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "processing-ui" },
                [
                  _c("el-progress", {
                    attrs: { type: "circle", percentage: 0 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("AI处理中")]),
                            _vm._v(" "),
                            _c("span", [_vm._v("请耐心等待")]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        文件正在经由AI分析处理中，整个过程可能需要几分钟，请勿关闭当前页面。\n      "
                    ),
                  ]),
                ],
                1
              ),
          _vm._v(" "),
          _vm.isPolling
            ? _c("FileProcessingPoller", {
                attrs: { fileIds: _vm.fileIds },
                on: {
                  "processing-complete": _vm.onProcessingComplete,
                  "processing-update": _vm.onProcessingUpdate,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.isProcessing || _vm.skipBtnIsDisabled,
                  },
                  on: { click: _vm.skipUpload },
                },
                [_vm._v("\n        不上传多模态文件\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.isProcessing || !_vm.uploadCompleted,
                  },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("\n        完成\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.multimodal_file_list.length
        ? _c("multiModeAnswerDialog", {
            attrs: {
              visible: _vm.multiModeAnswerdialogVisible,
              tableData: _vm.multimodal_file_list,
            },
            on: {
              "update:visible": function ($event) {
                _vm.multiModeAnswerdialogVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "NewV-btn-Child" }, [
      _c("div", { staticStyle: { "font-weight": "100", "margin-left": "0" } }, [
        _vm._v("+"),
      ]),
      _vm._v(" "),
      _c("div", {}, [_vm._v("新  的  估  值")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar-title" }, [
      _c("div", { staticClass: "sidebar-title-text" }, [
        _vm._v("\n        问题列表\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "comletion-rate" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }