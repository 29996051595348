"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Icon = _interopRequireDefault(require("./Icon.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    platform_id: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      isMobile: false,
      currentRow: null,
      balanceTypeDialogVisible: false
    };
  },
  components: {
    Icon: _Icon.default
  },
  methods: {
    checkScreenSize: function checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
    handleClick: function handleClick(row) {
      //   点击新的案例后 1.让用户选择是免费估值还是付费估值。2.跳转页面
      this.termsChecked = false;
      this.currentRow = row;
      this.balanceTypeDialogVisible = true;
      this.$emit("btn-click", {
        row: row
      });
    },
    tableHeader: function tableHeader() {
      return (/*css*/"  font-weight: 700;\n                        font-size: 16px;\n                        color: #000000;     "
      );
    }
  },
  mounted: function mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  }
};
exports.default = _default;