"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("./store"));
// you can set only in production env show the error-log
// if (process.env.NODE_ENV === 'production') {

_vue.default.config.errorHandler = function (err, vm, info, a) {
  // Don't ask me why I use Vue.nextTick, it just a hack.
  // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
  _vue.default.nextTick(function () {
    _store.default.dispatch('addErrorLog', {
      err: err,
      vm: vm,
      info: info,
      url: window.location.href
    });
    console.error(err, info);
  });
};

// }