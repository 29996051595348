"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setTitle = exports.loadStyle = void 0;
/**
 * 动态插入css
 */

var loadStyle = function loadStyle(url) {
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};
/**
 * 设置浏览器头部标题
 */
exports.loadStyle = loadStyle;
var setTitle = function setTitle(title) {
  title = title ? "".concat(title) : 'OxValue.AI';
  window.document.title = title;
};
exports.setTitle = setTitle;