"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/ubuntu/user-website-test/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _userlogin = _interopRequireDefault(require("./userlogin"));
var _codelogin = _interopRequireDefault(require("./codelogin"));
var _vuex = require("vuex");
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "login",
  components: {
    userLogin: _userlogin.default,
    codeLogin: _codelogin.default
  },
  data: function data() {
    return {};
  },
  created: function created() {
    var _this = this;
    // add direct login if route has arguments
    if (this.$route.query.jwt != undefined && this.$route.query.platform_id != undefined) {
      var post_data = {
        mode: "external_jwt",
        platform_id: this.$route.query.platform_id,
        jwt: this.$route.query.jwt
      };
      (0, _axios.default)({
        method: "POST",
        url: "https://api.oxvalue.ai:7999/api/auth/v0.1/user/",
        data: JSON.stringify(post_data)
      }).then(function (result) {
        if (result.data.code == 200) {
          window.localStorage.clear();
          window.localStorage.setItem("platform_profile", JSON.stringify(result.data.data.platform_profile));
          window.localStorage.setItem("ova_user_token", result.data.data.token);
          window.localStorage.setItem("ova_user_id", result.data.data.id);
          _this.$store.dispatch("Login", _this.loginForm).then(function (res) {
            _this.$router.push({
              path: "/"
            });
          });
        }
      });
    }
  },
  mounted: function mounted() {},
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["website"])),
  props: [],
  methods: {}
};
exports.default = _default;