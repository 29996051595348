"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Icon = _interopRequireDefault(require("./Icon.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Icon: _Icon.default
  },
  props: {
    qnr: {
      type: Object,
      required: true
    }
  },
  computed: {
    company_name: function company_name() {
      return this.qnr.description !== "" && JSON.parse(this.qnr.description)["companyName"] ? JSON.parse(this.qnr.description)["companyName"] : "未命名";
    }
  }
};
exports.default = _default;