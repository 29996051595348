"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.function.name");
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _elementUi = require("element-ui");
var _auth = require("@/utils/auth");
var _util = require("@/utils/util");
// Progress 进度条
// Progress 进度条样式

// 验权

// 设置浏览器头部标题

// permission judge function
function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('admin') >= 0) return true; // admin permission passed directly
  if (!permissionRoles) return true;
  return roles.some(function (role) {
    return permissionRoles.indexOf(role) >= 0;
  });
}
var whiteList = ['/login']; // 不重定向白名单
_router.default.beforeEach(function (to, from, next) {
  _nprogress.default.start();
  if ((0, _auth.getToken)()) {
    // 设置浏览器头部标题
    var browserHeaderTitle = to.name;
    _store.default.commit('SET_BROWSERHEADERTITLE', {
      browserHeaderTitle: browserHeaderTitle
    });
    /* has token*/
    if (_store.default.getters.isLock && to.path !== '/lock') {
      next({
        path: '/lock'
      });
      _nprogress.default.done();
    } else if (to.path === '/login') {
      next({
        path: '/'
      });
      _nprogress.default.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      next();
      // if (store.getters.roles.length === 0) {
      //   store.dispatch('GetInfo').then(res => { // 拉取用户信息
      //     console.log(res)
      //     const roles = res.roles // note: roles must be a array! such as: ['editor','develop']
      //     store.dispatch('GenerateRoutes', { roles }).then(() => { // 根据roles权限生成可访问的路由表
      //       router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
      //       next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
      //     })
      //   }).catch((err) => {
      //     store.dispatch('FedLogOut').then(() => {
      //       Message.error(err || 'Verification failed, please login again')
      //       next({ path: '/' })
      //     })
      //   })
      // } else {
      //   // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
      //   if (hasPermission(store.getters.roles, to.meta.roles)) {
      //     next()//
      //   } else {
      //     next({ path: '/401', replace: true, query: { noGoBack: true }})
      //   }
      // }
    }
  } else {
    // 设置浏览器头部标题
    var _browserHeaderTitle = to.name;
    _store.default.commit('SET_BROWSERHEADERTITLE', {
      browserHeaderTitle: _browserHeaderTitle
    });
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
      _nprogress.default.done();
    }
  }
});
_router.default.afterEach(function () {
  _nprogress.default.done(); // 结束Progress
  setTimeout(function () {
    var browserHeaderTitle = _store.default.getters.browserHeaderTitle;
    (0, _util.setTitle)(browserHeaderTitle);
  }, 0);
});