"use strict";

var _interopRequireDefault = require("/home/ubuntu/user-website-test/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.constructor");
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var MSGINIT = "Send Code";
// const MSGERROR = '验证码发送失败'
var MSGSCUCCESS = "Resend in ${time} secs";
var MSGTIME = 60;
var _default = {
  name: "codelogin",
  data: function data() {
    // const validatePhone = (rule, value, callback) => {
    //   if (isvalidatemobile(value)[0]) {
    //     callback(new Error(isvalidatemobile(value)[1]))
    //   } else {
    //     callback()
    //   }
    // }
    var validateCode = function validateCode(rule, value, callback) {
      if (value.length !== 4) {
        callback(new Error("Please enter 4 digits code"));
      } else {
        callback();
      }
    };
    return {
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      loginForm: {
        email: "",
        code: "",
        invitationCode: ""
      },
      loginRules: {
        // phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
        code: [{
          required: true,
          trigger: "blur",
          validator: validateCode
        }]
      }
    };
  },
  created: function created() {
    this.loginForm.invitationCode = this.getUrlParam("invitation_code") ? this.getUrlParam("invitation_code") : "";
    console.log(this.loginForm.invitationCode);
  },
  mounted: function mounted() {},
  computed: {},
  props: [],
  methods: {
    getUrlParam: function getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    handleSend: function handleSend() {
      var _this = this;
      if (this.msgKey) return;
      var post_data = {
        mode: "email",
        email: this.loginForm.email
      };
      (0, _axios.default)({
        method: "POST",
        url: "https://api.oxvalue.ai:7999/api/auth/v0.1/auth_code/",
        data: JSON.stringify(post_data)
      });
      this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
      this.msgKey = true;
      var time = setInterval(function () {
        _this.msgTime--;
        _this.msgText = MSGSCUCCESS.replace("${time}", _this.msgTime);
        if (_this.msgTime === 0) {
          _this.msgTime = MSGTIME;
          _this.msgText = MSGINIT;
          _this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      var post_data = {
        mode: "email_code",
        email: this.loginForm.email,
        auth_code: this.loginForm.code,
        invitation_code: this.loginForm.invitationCode
      };
      (0, _axios.default)({
        method: "POST",
        url: "https://api.oxvalue.ai:7999/api/auth/v0.1/user/",
        data: JSON.stringify(post_data)
      }).then(function (result) {
        console.log(result);
        if (result.data.code == 200) {
          window.localStorage.clear();
          window.localStorage.setItem("platform_profile", JSON.stringify(result.data.data.platform_profile));
          window.localStorage.setItem("ova_user_token", result.data.data.token);
          window.localStorage.setItem("ova_user_id", result.data.data.id);
          window.localStorage.setItem("user_email", result.data.data.email);
          _this2.$store.dispatch("Login", _this2.loginForm).then(function (res) {
            _this2.$router.push({
              path: "/"
            });
          });
        } else {
          _this2.$message.error("Wrong email or code！");
        }
      });
    }
  }
};
exports.default = _default;