var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "multi-modal-uploader" }, [
    !_vm.isProcessing
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "audio-recorder" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { disabled: !_vm.isAudioSupported },
                    on: { click: _vm.toggleRecording },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.isRecording ? "停止录音" : "开始录音") +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isRecording
                  ? _c("span", { staticClass: "recording-indicator" }, [
                      _vm._v("正在录音..."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "file-selector" },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "#",
                      "auto-upload": false,
                      "on-change": _vm.handleFileChange,
                      multiple: "",
                      "show-file-list": false,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          slot: "trigger",
                          size: "small",
                          type: "primary",
                        },
                        slot: "trigger",
                      },
                      [_vm._v("选择文件")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "upload-queue",
                staticStyle: { height: "36vh", "overflow-y": "auto" },
              },
              [
                _c("h4", [_vm._v("待上传项目:")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.allFiles },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "文件名" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "type", label: "类型" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "400" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "operation-container" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "delete-button",
                                        attrs: { type: "danger", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItem(scope.$index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                删除\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.isAudioFile(scope.row)
                                      ? _c("audio", {
                                          staticClass: "audio-player",
                                          attrs: {
                                            controls: "",
                                            src: _vm.getFileUrl(scope.row),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2924455715
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  disabled: !_vm.hasItemsToUpload || _vm.isUploading,
                  type: "primary",
                },
                on: { click: _vm.uploadAll },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.isUploading ? "上传中..." : "上传所有文件") +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isUploading
              ? _c("el-progress", {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { percentage: _vm.uploadProgress },
                })
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "processing-ui" },
          [
            _c("el-progress", {
              attrs: { type: "circle", percentage: 0 },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("span", [_vm._v("AI处理中")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("请耐心等待")]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      文件正在经由AI分析处理中，整个过程可能需要几分钟，请勿关闭当前页面。\n    "
              ),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }