var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pageContainer",
      class: { "sidebar-open": _vm.isSidebarOpen },
    },
    [
      _c("div", { staticClass: "left" }, [_vm._t("left")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "head" }, [_vm._t("head")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "main" }, [_vm._t("main")], 2),
      ]),
      _vm._v(" "),
      _vm.isSidebarOpen
        ? _c("div", { staticClass: "overlay", on: { click: _vm.closeSidebar } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }